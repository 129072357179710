import * as React from "react";
import { Sms, SecuritySafe, User } from "iconsax-react";
import { FormTextField } from "./formTextField";
import { Box, Typography } from "@mui/material";
interface SignUpFormProps {
    email: string;
    password: string;
    confirmPassword: string;
    agreeToPolicy: boolean;
    onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onConfirmPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    onAgreeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    firstName: string;
    lastName: string;
    error?: string;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({
    email,
    password,
    confirmPassword,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit,
    firstName,
    lastName,
    error,
}) => {
    return (
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 2 }}>
                <FormTextField
                    label="First Name"
                    id="first_name"
                    name="first_name"
                    placeholder="Enter name…"
                    autoComplete="first_name"
                    autoFocus
                    value={firstName}
                    disabled
                    icon={<User size={20} />}
                />
                <FormTextField
                    label="Last Name"
                    id="last_name"
                    name="last_name"
                    placeholder="Enter name…"
                    autoComplete="last_name"
                    autoFocus
                    value={lastName}
                    disabled
                    icon={<User size={20} />}
                />
            </Box>
            <FormTextField
                label="Email address"
                id="email"
                name="email"
                placeholder="Enter email…"
                autoComplete="email"
                autoFocus
                disabled
                value={email}
                icon={<Sms size={20} />}
            />
            <FormTextField
                label="Password"
                name="password"
                placeholder="Enter password…"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                icon={<SecuritySafe size={20} />}
                onChange={onPasswordChange}
            />
            <FormTextField
                label="Confirm Password"
                name="confirm_password"
                placeholder="Confirm password…"
                type="password"
                id="confirm_password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                icon={<SecuritySafe size={20} />}
            />
            {/* <FormControlLabel
                control={
                    <Checkbox
                        checked={agreeToPolicy}
                        onChange={onAgreeChange}
                        name="agreeToPolicy"
                        color="primary"
                        icon={
                            <Box
                                sx={{
                                    borderRadius: 1.5,
                                    width: 20,
                                    height: 20,
                                    m: "2px",
                                    border: "1px solid",
                                    borderColor: "divider",
                                }}
                            />
                        }
                        sx={theme => ({
                            borderRadius: 4,
                            color: "divider",
                            "&.Mui-checked": {
                                color: theme.palette.secondary.main,
                            },
                        })}
                    />
                }
                label={
                    <Typography variant="body2">
                        I agree to AnswerGrid&apos;s{" "}
                        <Link
                            color="secondary"
                            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=227e0944-e1cc-43f3-9cfc-efbd59624577"
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                }
            /> */}

            {error && (
                <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};
