import * as React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { User, ArrowDown2, ArrowUp2 } from "iconsax-react";
import { PersonData } from "./types";
import Avatar from "@mui/material/Avatar";
import { countries } from "countries-list";
import { ReactComponent as BackgroundPattern } from "../assets/profile-card-pattern.svg";
import { comparatorOnFields } from "../utils/comparators";
import { ProjectForPerson, ProjectForPersonProps } from "./projectForPerson";

type PersonAnswerSectionProps = {
    people: PersonData[];
    onSelectPerson: (idx: number, projectIdx: number | undefined) => void;
};

export const PersonAnswerSection = React.memo(({ people, onSelectPerson }: PersonAnswerSectionProps) => {
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const toggleCollapse = React.useCallback((shouldScroll?: boolean) => {
        setIsCollapsed(prev => {
            if (shouldScroll && !prev) {
                containerRef.current?.scrollIntoView();
            }
            return !prev;
        });
    }, []);

    const handleShowLess = React.useCallback(() => {
        toggleCollapse(true);
    }, [toggleCollapse]);

    const visiblePeople = React.useMemo(() => {
        return isCollapsed ? people.slice(0, 4) : people;
    }, [isCollapsed, people]);

    const hiddenPeople = React.useMemo(() => {
        return isCollapsed ? people.slice(4) : [];
    }, [isCollapsed, people]);

    return (
        <Box
            ref={containerRef}
            sx={{
                display: "flex",
                flexDirection: "column",
                width: 690,
                mb: 3,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1.5,
                    width: "100%",
                    mb: 2,
                }}
            >
                {visiblePeople.map((person, idx) => (
                    <PersonCard key={person.unique_id} person={person} idx={idx} onSelect={onSelectPerson} />
                ))}
            </Box>
            {hiddenPeople.length > 0 && isCollapsed && (
                <ShowMoreButton hiddenPeople={hiddenPeople} onClick={toggleCollapse} />
            )}
            {!isCollapsed && <ShowLessButton onClick={handleShowLess} />}
        </Box>
    );
});

PersonAnswerSection.displayName = "PersonAnswerSection";

type PersonCardProps = {
    person: PersonData;
    idx: number;
    onSelect: (idx: number, projectIdx: number | undefined) => void;
};

const CARD_WIDTH = 320;
const PROFILE_SIZE = 104;
const PATTERN_HEIGHT = 76;

const PersonCard = React.memo(({ person, idx, onSelect }: PersonCardProps) => {
    const locationText = React.useMemo(() => {
        if (person.city == null && person.country == null) return null;
        const countryName = person.country ? getCountryName(person.country) : null;
        return [person.city, countryName].filter(Boolean).join(", ");
    }, [person.city, person.country]);

    const projectsSortedByStartDate = React.useMemo(() => {
        return [...person.projects].sort(
            comparatorOnFields(project => [
                project.start_date ? -new Date(project.start_date).getTime() : 0,
                project.end_date ? -new Date(project.end_date).getTime() : 0,
            ]),
        );
    }, [person.projects]);

    const handleSelectPerson = React.useCallback(() => {
        onSelect(idx, undefined);
    }, [idx, onSelect]);

    const handleSelectProject = React.useCallback(
        (projectIdx: number) => {
            onSelect(idx, projectIdx);
        },
        [idx, onSelect],
    );

    return (
        <Box
            sx={{
                width: CARD_WIDTH,
                height: 450,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                bgcolor: "white",
                border: 1,
                borderColor: "neutrals.25",
                borderRadius: 2,
                position: "relative",
                // overflow: "hidden",
                pb: 1,
            }}
        >
            <Box
                sx={theme => ({
                    position: "relative",
                    minHeight: `calc(${PATTERN_HEIGHT}px + ${PROFILE_SIZE / 2}px + ${theme.spacing(1.5)})`,
                    // overflow: "hidden",
                    width: "100%",
                })}
            >
                <BackgroundPattern style={{ width: "100%", height: PATTERN_HEIGHT }} />

                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        top: `calc(${PATTERN_HEIGHT}px - ${PROFILE_SIZE / 2}px)`,
                        transform: "translateX(-50%)",
                        p: 0.5,
                        bgcolor: "white",
                        borderRadius: "50%",
                    }}
                >
                    <ProfilePicture
                        imageUrl={person.profile_picture_url ?? undefined}
                        firstName={person.first_name}
                        lastName={person.last_name}
                        size={PROFILE_SIZE}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    px: 1,
                    overflow: "auto",
                    zIndex: 2,
                }}
            >
                <Typography
                    variant="body1"
                    color="neutrals.80"
                    fontWeight={500}
                    align="center"
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mb: 0.5,
                    }}
                >
                    {person.first_name} {person.last_name}
                </Typography>

                <Typography
                    variant="body2"
                    color="neutrals.60"
                    align="center"
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mb: 3,
                    }}
                >
                    {person.title} {locationText && `- ${locationText}`}
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>
                    {projectsSortedByStartDate.slice(0, 2).map((project, projectIdx) => (
                        <ProjectForPersonWithIdx
                            key={project.id}
                            project={project}
                            personId={person.unique_id}
                            projectIdx={projectIdx}
                            onSelect={handleSelectProject}
                        />
                    ))}
                </Box>
            </Box>
            <Button
                variant="outlined"
                onClick={handleSelectPerson}
                sx={{
                    mt: 1,
                    color: "secondary.main",
                    borderColor: "secondary.main",
                    textTransform: "none",
                    mx: 1,
                }}
            >
                View {person.projects.length} relevant projects
            </Button>
        </Box>
    );
});

interface ProjectForPersonWithIdxProps extends Omit<ProjectForPersonProps, "onSelect"> {
    personId: string;
    projectIdx: number;
    onSelect: (projectIdx: number) => void;
}

const ProjectForPersonWithIdx = React.memo(
    ({ project, personId, projectIdx, onSelect }: ProjectForPersonWithIdxProps) => {
        const handleSelect = React.useCallback(() => {
            onSelect(projectIdx);
        }, [projectIdx, onSelect]);

        return <ProjectForPerson project={project} personId={personId} onSelect={handleSelect} />;
    },
);

ProjectForPersonWithIdx.displayName = "ProjectForPersonWithIdx";

PersonCard.displayName = "PersonCard";

type ProfilePictureProps = {
    imageUrl?: string;
    firstName: string;
    lastName: string;
    size: number;
};

const ProfilePicture = React.memo(({ imageUrl, firstName, lastName, size }: ProfilePictureProps) => {
    const [imageError, setImageError] = React.useState(false);
    const theme = useTheme();

    const handleImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    if (imageError || imageUrl == null) {
        return (
            <Box
                sx={{
                    width: size,
                    height: size,
                    borderRadius: "50%",
                    bgcolor: "neutrals.10",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <User size={size / 2} color={theme.palette.secondary.main} variant="Bold" />
            </Box>
        );
    }

    return (
        <Box
            component="img"
            src={imageUrl}
            alt={`${firstName} ${lastName}`}
            onError={handleImageError}
            sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                objectFit: "cover",
            }}
        />
    );
});

ProfilePicture.displayName = "ProfilePicture";
type ShowMoreButtonProps = {
    hiddenPeople: PersonData[];
    onClick: () => void;
};

const AVATARS_COUNT = 4;
const AVATAR_SIZE = 32;

const ShowMoreButton = React.memo(({ hiddenPeople, onClick }: ShowMoreButtonProps) => {
    const displayedAvatars = React.useMemo(() => {
        const avatars = hiddenPeople.slice(0, AVATARS_COUNT).map((person, index) => (
            <Avatar
                key={person.unique_id}
                src={person.profile_picture_url ?? undefined}
                alt={`${person.first_name} ${person.last_name}`}
                sx={{
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                    ml: index === 0 ? 0 : -1.2, // Overlap by ~20%
                    border: 2,
                    borderColor: "white",
                    zIndex: index + 1,
                }}
            />
        ));
        const remainingCount = hiddenPeople.length - AVATARS_COUNT;

        if (remainingCount > 0) {
            avatars.push(
                <Box
                    key="remaining"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ml: -1.2,
                        bgcolor: "surface.25",
                        border: 1,
                        borderColor: "white",
                        height: AVATAR_SIZE,
                        width: AVATAR_SIZE,
                        borderRadius: "50%",
                        zIndex: AVATARS_COUNT + 1,
                    }}
                >
                    <Typography variant="body2" color="secondary.main" fontWeight={550}>
                        +{remainingCount}
                    </Typography>
                </Box>,
            );
        }

        return avatars;
    }, [hiddenPeople]);

    return (
        <Box
            onClick={onClick}
            sx={theme => ({
                bgcolor: "white",
                height: 56,
                border: 1,
                borderColor: "neutrals.25",
                borderRadius: 2,
                padding: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                color: "secondary.main",
                width: `calc(${2 * CARD_WIDTH}px +  ${theme.spacing(1.5)})`,
                "&:hover": {
                    bgcolor: "neutrals.10",
                },
            })}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>{displayedAvatars}</Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mr: 0.5 }}>
                <Typography variant="body2" color="secondary.main" fontWeight={550}>
                    Show more
                </Typography>
                <ArrowDown2 size={16} variant="Bold" />
            </Box>
        </Box>
    );
});

ShowMoreButton.displayName = "ShowMoreButton";

interface ShowLessButtonProps {
    onClick: () => void;
}

const ShowLessButton = React.memo(({ onClick }: ShowLessButtonProps) => {
    return (
        <Button
            onClick={onClick}
            fullWidth
            variant="outlined"
            sx={theme => ({
                justifyContent: "center",
                textTransform: "none",
                bgcolor: "white",
                borderColor: "neutrals.25",
                borderRadius: 2,
                color: "secondary.main",
                padding: 1.5,
                gap: 0.5,
                display: "flex",
                alignItems: "center",
                width: `calc(${2 * CARD_WIDTH}px +  ${theme.spacing(1.5)})`,
                "&:hover": {
                    bgcolor: "neutrals.30",
                },
            })}
        >
            <Typography variant="body2">Show less</Typography>
            <ArrowUp2 size={16} variant="Bold" />
        </Button>
    );
});

ShowLessButton.displayName = "ShowLessButton";

const getCountryName = (isoCode: string) => {
    const country = (countries as Record<string, { name: string }>)[isoCode.toUpperCase()];
    return country?.name ?? isoCode;
};
