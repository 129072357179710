import * as React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import { TickSquare } from "iconsax-react";
import { Box } from "@mui/material";

interface AnswerGridCheckboxProps extends Omit<CheckboxProps, "onChange"> {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const ICON_SIZE = 16;

export const AnswerGridCheckbox: React.FC<AnswerGridCheckboxProps> = ({
    checked,
    onChange,
    ...props
}: AnswerGridCheckboxProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <Checkbox
            checked={checked}
            onChange={handleChange}
            checkedIcon={<TickSquare variant="Bold" size={ICON_SIZE + 2} />}
            icon={
                <Box
                    sx={{
                        borderRadius: 1.5,
                        width: ICON_SIZE,
                        height: ICON_SIZE,
                        m: "1px",
                        border: "1px solid",
                        borderColor: "divider",
                    }}
                />
            }
            sx={theme => ({
                borderRadius: 4,
                color: "divider",
                "&.Mui-checked": {
                    color: theme.palette.secondary.main,
                },
            })}
            {...props}
        />
    );
};
