import * as React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Document } from "iconsax-react";

interface TenantFocusedDocumentItemProps {
    fileName: string;
}

export const TenantFocusedDocumentItem = ({ fileName }: TenantFocusedDocumentItemProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                px: 1,
                py: 0.5,
                borderRadius: 1,
                border: 1,
                borderColor: "neutrals.20",
            }}
        >
            <Document size={16} color={theme.palette.neutrals[80]} />
            <Typography variant="body2" noWrap sx={{ flex: 1 }}>
                {fileName}
            </Typography>
        </Box>
    );
};
