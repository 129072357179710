import Box from "@mui/material/Box";
import * as React from "react";
import "../App.css";
import { Sidebar } from "./sidebar";

export interface SidebarLayoutProps {
    children: React.ReactNode;
    footer?: React.ReactNode;
}

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children, footer }) => {
    return (
        <Box
            sx={theme => ({
                display: "flex",
                alignItems: "stretch",
                p: 2,
                height: "100vh",
                overflowY: "hidden",
                maxWidth: "100vw",
                backgroundColor: theme.palette.surface[50],
                columnGap: 1,
                position: "relative",
            })}
        >
            <Sidebar />
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    alignItems: "stretch",
                }}
            >
                {children}
                {footer}
            </Box>
        </Box>
    );
};
