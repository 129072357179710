import { ChatSourceFilters } from "../backend-client/generated";
import { Source } from "../search/types";

export type SourceType = NonNullable<ChatSourceFilters["source_types"]>[number];

export const SOURCE_TYPES = ["fusioo", "answergrid"] as const;

export const asSourceType = (sourceType: string): SourceType | undefined => {
    return SOURCE_TYPES.includes(sourceType as SourceType) ? (sourceType as SourceType) : undefined;
};

export type FileType = NonNullable<Source["file_type"]>;

export const FILE_TYPES = ["pdf", "docx", "pptx", "xlsx"] as const;

export const asFileType = (fileType: string): FileType | undefined => {
    return FILE_TYPES.includes(fileType as FileType) ? (fileType as FileType) : undefined;
};
