import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./App.css";

declare module "@mui/material/styles" {
    interface Palette {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            20: string;
            10: string;
            5: string;
        };
        citations: {
            main: string;
        };
        selection: {
            main: string;
        };
        orange: {
            main: string;
        };
    }

    interface PaletteOptions {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            25: string;
            20: string;
            10: string;
            5: string;
        };
        citations: {
            main: string;
        };
        selection: {
            main: string;
        };
        orange: {
            main: string;
        };
    }

    interface Theme {
        name: "default" | "blue-1" | "black-1" | "blue-2";
    }

    interface ThemeOptions {
        name?: "default" | "blue-1" | "black-1" | "blue-2";
    }
}

export const defaultTheme = createTheme({
    name: "default",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F8F8F7",
        },
        secondary: {
            light: "#EFF2ED",
            main: "#365934",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: red.A400,
        },
        surface: {
            100: "#E3DAC8",
            75: "#F0EDE6",
            50: "#F4F2EF",
            25: "#F8F8F7",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E7E6E4",
            25: "#F0F0EF",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#669461",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#0288d1",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blueCustomTheme = createTheme({
    name: "blue-1",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F7F8F8",
        },
        secondary: {
            // TODO: Freestyle
            light: "#7bc3d4",
            main: "#066C83",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: "#F62D44",
        },
        surface: {
            100: "#C8DEE3",
            75: "#E6EFF0",
            50: "#EFF3F4",
            25: "#F7F8F8",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E4E6E7",
            25: "#EFF0F0",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#F2672C",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#F2672C",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blueCustomTheme2 = createTheme({
    name: "blue-2",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F7F8F8",
        },
        secondary: {
            // Updated for the new theme
            light: "#71AEDD", // A lighter tone of #0158A7
            main: "#0158A7",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: "#F62D44",
        },
        surface: {
            100: "#B5D5E5", // Adjusted to complement #0158A7
            75: "#D4E4F2",
            50: "#E7F1F9",
            25: "#F2F8FC",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E4E6E7",
            25: "#EFF0F0",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#F2672C",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#F2672C",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blackCustomTheme = createTheme({
    name: "black-1",
    palette: {
        primary: {
            light: "#CCCCCC", // Light gray
            main: "#FFFFFF", // White (inverted from black)
            contrastText: "#000000", // Black text on white
        },
        secondary: {
            light: "#333333", // Dark gray
            main: "#000000", // Black
            contrastText: "#FFFFFF", // Ensures text is readable on primary color
        },
        success: {
            main: "#4A4A4A", // Medium dark gray
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#B00020", // Dark red converted to dark gray
            contrastText: "#FFFFFF",
        },
        surface: {
            100: "#CCCCCC", // Light gray
            75: "#E6E6E6", // Very light gray
            50: "#F5F5F5", // Almost white
            25: "#FFFFFF", // White
        },
        neutrals: {
            // Start of Selection
            100: "#000000", // Black
            90: "#010101", // Almost black
            80: "#0A0A0A", // Very dark gray
            70: "#232323", // Dark gray
            60: "#3D3D3D", // Medium dark gray
            50: "#565656", // Medium gray
            40: "#707070", // Gray
            30: "#898989", // Light gray
            25: "#A3A3A3", // Lighter gray
            20: "#A3A3A3", // Lighter gray
            10: "#CCCCCC", // Dark gray
            5: "#D6D6D6", // Near white
        },
        selection: {
            main: "#666666", // Medium gray
        },
        text: {
            primary: "#000000", // Kept black text
            secondary: "#333333", // Dark gray text
            disabled: "#666666", // Medium gray for disabled text
        },
        citations: {
            main: "#666666", // Medium gray
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});
