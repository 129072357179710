import React from "react";
import { Box, Button, SxProps, Theme } from "@mui/material";
import { Filter } from "./filter";
import { Calendar2, Component, DocumentText, Folder } from "iconsax-react";
import { ProjectFilter } from "./projectFilter";
import { tenantQueryOptions } from "../chat/queryOptions";
import { useQuery } from "@tanstack/react-query";
import { SourceTypeFilter } from "./sourceFilter";
import { FileTypeFilter } from "./fileTypeFilter";
import { DateFilter } from "./dateFilter";
import { SourceType, FileType } from "../shared/types";

interface FiltersProps {
    projects: string[];
    onProjectsChange: (projects: string[]) => void;
    sourceTypes: SourceType[];
    onSourceTypesChange: (sourceTypes: SourceType[]) => void;
    fileTypes: FileType[];
    onFileTypesChange: (fileTypes: FileType[]) => void;
    earliestDate: Date | undefined;
    onEarliestDateChange: (date: Date | undefined) => void;
    sx?: SxProps<Theme>;
    showGoButton?: boolean;
    onGoClick?: () => void;
}

const ICON_SIZE = 16;

export const Filters: React.FC<FiltersProps> = ({
    projects,
    onProjectsChange,
    sourceTypes: sources,
    onSourceTypesChange: onSourcesChange,
    fileTypes: filterFileTypes,
    onFileTypesChange: onFilterFileTypesChange,
    earliestDate: selectedDate,
    onEarliestDateChange: onDateChange,
    sx,
    showGoButton,
    onGoClick,
}) => {
    const isTenantWithFilters = useQuery({ ...tenantQueryOptions, select: data => data.enable_filters ?? false });

    return (
        <Box
            sx={[
                { display: "flex", alignItems: "center", justifyContent: "space-between" },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {isTenantWithFilters ? (
                    <DateFilter selectedDate={selectedDate} onDateChange={onDateChange} />
                ) : (
                    <Filter
                        icon={<Calendar2 size={ICON_SIZE} />}
                        text="Anytime"
                        content={undefined} // Empty content for now
                    />
                )}
                {isTenantWithFilters ? (
                    <SourceTypeFilter selectedSources={sources} onSourcesChange={onSourcesChange} />
                ) : (
                    <Filter
                        icon={<Component size={ICON_SIZE} />}
                        text="Source"
                        content={undefined} // Empty content for now
                    />
                )}
                {isTenantWithFilters ? (
                    <FileTypeFilter selectedFileTypes={filterFileTypes} onFileTypesChange={onFilterFileTypesChange} />
                ) : (
                    <Filter
                        icon={<DocumentText size={ICON_SIZE} />}
                        text="File type"
                        content={undefined} // Empty content for now
                    />
                )}
                {isTenantWithFilters ? (
                    <ProjectFilter selectedProjects={projects} onProjectsChange={onProjectsChange} mode="text-input" />
                ) : (
                    <Filter
                        icon={<Folder size={ICON_SIZE} />}
                        text="Project"
                        content={undefined} // Empty content for now
                    />
                )}
            </Box>
            {showGoButton && (
                <Button color="secondary" variant="outlined" size="small" onClick={onGoClick}>
                    Go
                </Button>
            )}
        </Box>
    );
};
