import * as React from "react";
import { Popper, Box } from "@mui/material";

interface FocusedDocumentPopperProps {
    children: React.ReactNode;
    anchorEl: HTMLElement | null;
}

export const FocusedDocumentPopper = ({ children, anchorEl }: FocusedDocumentPopperProps) => {
    return (
        <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ zIndex: theme => theme.zIndex.tooltip }}
        >
            <Box
                sx={{
                    mt: 1,
                    p: 1.5,
                    bgcolor: "white",
                    borderRadius: 1,
                    boxShadow: theme => theme.shadows[2],
                    border: 1,
                    borderColor: "neutrals.20",
                    maxWidth: 300,
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>{children}</Box>
            </Box>
        </Popper>
    );
};
