import { H } from "highlight.run";
import { coreAuthRouterGetCurrentUser, coreAuthRouterGetTenant } from "../backend-client/generated";
import { currentUserQueryOptions, tenantQueryOptions } from "../chat/queryOptions";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { QueryClient } from "@tanstack/react-query";
import * as React from "react";

export const useAuthenticationSuccess = (queryClient: QueryClient) => {
    const handleAuthenticationSuccess = React.useCallback(
        async (token: string) => {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);

            try {
                const userResponse = await coreAuthRouterGetCurrentUser({
                    throwOnError: true,
                    credentials: "include",
                });

                queryClient.setQueryData(currentUserQueryOptions.queryKey, userResponse.data);

                // Identify user in Highlight
                H.identify(userResponse.data.username, {
                    email: userResponse.data.email,
                    id: userResponse.data.id.toString(),
                });
            } catch (error) {
                console.error("Failed to fetch user details:", error);
            }

            let pathToGoTo = "/";
            try {
                const tenant = await coreAuthRouterGetTenant({
                    throwOnError: true,
                    credentials: "include",
                });
                if (tenant.data.default_app === "chat") {
                    pathToGoTo = "/chat";
                }
                queryClient.setQueryData(tenantQueryOptions.queryKey, tenant.data);
            } catch (error) {
                console.error("Failed to fetch tenant details:", error);
            }

            window.location.href = pathToGoTo;
        },
        [queryClient],
    );

    return handleAuthenticationSuccess;
};
