import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowLeft2, ArrowRight2, Filter } from "iconsax-react";
import { ProjectFilter } from "../search/projectFilter";
import { SourceTypeFilter } from "../search/sourceFilter";
import { FileTypeFilter } from "../search/fileTypeFilter";
import { DateFilter } from "../search/dateFilter";
import { FileType, SourceType } from "../shared/types";

const FILTER_SX = { bgcolor: "white", border: 1, borderColor: "neutrals.30", width: 130 } as const;

interface SourceFiltersProps {
    isExpanded: boolean;
    onExpandChange: (expanded: boolean) => void;
    disabledEditingFiltersReason: string | undefined;
    projects: string[];
    onProjectsChange: (projects: string[]) => void;
    sourceTypes: SourceType[];
    onSourceTypesChange: (sourceTypes: SourceType[]) => void;
    fileTypes: FileType[];
    onFileTypesChange: (fileTypes: FileType[]) => void;
    earliestDate: Date | undefined;
    onEarliestDateChange: (date: Date | undefined) => void;
}

export const SourceFilters: React.FC<SourceFiltersProps> = ({
    disabledEditingFiltersReason,
    isExpanded,
    onExpandChange,
    projects,
    onProjectsChange,
    sourceTypes,
    onSourceTypesChange,
    fileTypes,
    onFileTypesChange,
    earliestDate,
    onEarliestDateChange,
}) => {
    const handleExpandClick = React.useCallback(() => {
        onExpandChange(true);
    }, [onExpandChange]);

    const handleCollapseClick = React.useCallback(() => {
        onExpandChange(false);
    }, [onExpandChange]);

    const filterCount =
        (projects.length > 0 ? 1 : 0) +
        (sourceTypes.length > 0 ? 1 : 0) +
        (fileTypes.length > 0 ? 1 : 0) +
        (earliestDate != null ? 1 : 0);

    if (!isExpanded) {
        return (
            <Box
                onClick={handleExpandClick}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    bgcolor: "white",
                    py: 0.5,
                    pl: 1,
                    pr: 0.5,
                    borderRadius: 2,
                    cursor: "pointer",
                    border: 1,
                    borderColor: "neutrals.30",
                    height: 32,
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
                        <Filter size={16} />
                        {filterCount > 0 && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: -2,
                                    right: -2,
                                    width: 8,
                                    height: 8,
                                    borderRadius: "50%",
                                    bgcolor: "secondary.main",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: "white",
                                        fontSize: "8px",
                                        lineHeight: 1,
                                    }}
                                >
                                    {filterCount}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Typography variant="caption" sx={{ fontWeight: 550 }}>
                        Source Filters
                    </Typography>
                </Box>
                <ArrowRight2 size={16} />
            </Box>
        );
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DateFilter
                selectedDate={earliestDate}
                onDateChange={onEarliestDateChange}
                sx={FILTER_SX}
                disabledReason={disabledEditingFiltersReason}
                iconSize={16}
                textVariant="caption"
            />
            <SourceTypeFilter
                selectedSources={sourceTypes}
                onSourcesChange={onSourceTypesChange}
                sx={FILTER_SX}
                iconSize={16}
                textVariant="caption"
            />
            <FileTypeFilter
                selectedFileTypes={fileTypes}
                onFileTypesChange={onFileTypesChange}
                sx={FILTER_SX}
                iconSize={16}
                textVariant="caption"
            />
            <ProjectFilter
                selectedProjects={projects}
                onProjectsChange={onProjectsChange}
                mode="text-input"
                sx={FILTER_SX}
                iconSize={16}
                textVariant="caption"
            />
            <IconButton onClick={handleCollapseClick} sx={{ ml: 1, border: 1, borderColor: "" }}>
                <ArrowLeft2 size={20} />
            </IconButton>
        </Box>
    );
};
