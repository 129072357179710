import React from "react";
import { TextField, Typography, Box, InputAdornment, TextFieldProps, useTheme } from "@mui/material";

interface FormTextFieldProps extends Omit<TextFieldProps, "variant"> {
    label: string;
    icon: React.ReactNode;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({ label, icon, ...props }) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption">{label}</Typography>
            <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                inputProps={{
                    sx: {
                        typography: "body2",
                    },
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                }}
                {...props}
                sx={[
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
                    {
                        mt: 0,

                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.neutrals[60],
                        },
                    },
                ]}
            />
        </Box>
    );
};
