import * as React from "react";
import { ChatContext } from "./contextInfo";
import { ContextSwitcherButton } from "./contextSwitcherButton";

interface TenantFileContextSwitcherProps {
    currentContext: ChatContext;
}

const NO_OP = () => {
    return;
};

export const TenantFileContextSwitcher = ({ currentContext }: TenantFileContextSwitcherProps) => {
    return <ContextSwitcherButton canEditFocus={false} currentContext={currentContext} onClick={NO_OP} />;
};
