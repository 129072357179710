import { Box, Typography } from "@mui/material";
import { DocumentText } from "iconsax-react";
import * as React from "react";

interface DocCountIconProps {
    focusedDocumentCount: number;
    canEditFocus: boolean;
    onMouseEnter: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave: () => void;
}

export const DocCountIcon: React.FC<DocCountIconProps> = ({
    focusedDocumentCount,
    canEditFocus,
    onMouseEnter,
    onMouseLeave,
}) => {
    if (focusedDocumentCount === 0) {
        return null;
    }

    return (
        <Box
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "white",
                borderRadius: "50%",
                border: 1,
                borderColor: "neutrals.30",
                height: 32,
                width: 32,
                cursor: canEditFocus ? "pointer" : "default",
            }}
        >
            <DocumentText size={16} />
            <Box
                sx={{
                    position: "absolute",
                    top: -4,
                    right: -4,
                    bgcolor: "secondary.main",
                    borderRadius: "50%",
                    width: 14,
                    height: 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        color: "white",
                        fontSize: "10px",
                        lineHeight: 1,
                    }}
                >
                    {focusedDocumentCount}
                </Typography>
            </Box>
        </Box>
    );
};

DocCountIcon.displayName = "DocCountIcon";
