import * as React from "react";
import { Box, FormControlLabel, FormGroup, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { Filter } from "./filter";
import { DocumentText } from "iconsax-react";
import { FileType } from "../shared/types";
import { AnswerGridCheckbox } from "../components/answergridCheckbox";

interface FileTypeFilterProps {
    selectedFileTypes: FileType[];
    onFileTypesChange: (fileTypes: FileType[]) => void;
    sx?: SxProps<Theme>;
    disabledReason?: string;
    iconSize?: number;
    textVariant?: TypographyProps["variant"];
}

type SelectableFileType = {
    label: string;
    value: FileType;
};

const fileTypes: SelectableFileType[] = [
    { label: "PDF", value: "pdf" },
    { label: "Word document", value: "docx" },
    { label: "Excel sheet", value: "xlsx" },
    { label: "Powerpoint deck", value: "pptx" },
];

export const FileTypeFilter: React.FC<FileTypeFilterProps> = ({
    selectedFileTypes,
    onFileTypesChange,
    sx,
    disabledReason,
    iconSize = 20,
    textVariant = "body2",
}) => {
    const handleFileTypeChange = React.useCallback(
        (fileType: FileType) => {
            onFileTypesChange(
                selectedFileTypes.includes(fileType)
                    ? selectedFileTypes.filter(t => t !== fileType)
                    : [...selectedFileTypes, fileType],
            );
        },
        [selectedFileTypes, onFileTypesChange],
    );

    const content = React.useMemo(
        () => (
            <Box sx={{ minWidth: 140 }}>
                <FormGroup>
                    {fileTypes.map(fileType => (
                        <FormControlLabel
                            key={fileType.value}
                            disabled={disabledReason != null}
                            control={
                                <AnswerGridCheckbox
                                    checked={selectedFileTypes.includes(fileType.value)}
                                    onChange={() => handleFileTypeChange(fileType.value)}
                                />
                            }
                            label={<Typography variant="body2">{fileType.label}</Typography>}
                            sx={{ py: 0.5, pl: 1 }}
                        />
                    ))}
                </FormGroup>
            </Box>
        ),
        [selectedFileTypes, handleFileTypeChange, disabledReason],
    );

    return (
        <Filter
            icon={<DocumentText size={iconSize} />}
            text={`File Type${selectedFileTypes.length > 0 ? ` (${selectedFileTypes.length})` : ""}`}
            content={content}
            sx={sx}
            textVariant={textVariant}
        />
    );
};
