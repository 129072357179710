import * as React from "react";
import { Box, Theme, SxProps, TypographyProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Filter } from "./filter";
import { Calendar2 } from "iconsax-react";

interface DateFilterProps {
    selectedDate: Date | undefined;
    onDateChange: (date: Date | undefined) => void;
    sx?: SxProps<Theme>;
    disabledReason?: string;
    iconSize?: number;
    textVariant?: TypographyProps["variant"];
}

export const DateFilter: React.FC<DateFilterProps> = ({
    selectedDate,
    onDateChange,
    sx,
    disabledReason,
    iconSize = 20,
    textVariant = "body2",
}) => {
    const handleDateChange = React.useCallback(
        (newDate: Date | null) => {
            onDateChange(newDate ?? undefined);
        },
        [onDateChange],
    );

    const content = React.useMemo(
        () => (
            <Box
                sx={{
                    minWidth: 280,
                    p: 0.5,
                    "& .MuiInputBase-root": {
                        fontSize: "0.875rem",
                    },
                }}
            >
                <DatePicker
                    label="Documents after date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    disabled={disabledReason != null}
                    slotProps={{
                        textField: {
                            size: "small",
                            fullWidth: true,
                            label: undefined,
                            hiddenLabel: true,
                            helperText: "Show documents after this date",
                            sx: {
                                backgroundColor: "background.paper",
                                "& .MuiFormHelperText-root": {
                                    mx: 0,
                                    mt: 0.5,
                                },
                            },
                        },
                    }}
                />
            </Box>
        ),
        [selectedDate, handleDateChange, disabledReason],
    );

    const displayText = React.useMemo(() => {
        if (!selectedDate) return "Date";
        return `Date (1)`;
    }, [selectedDate]);

    return (
        <Filter
            icon={<Calendar2 size={iconSize} />}
            text={displayText}
            content={content}
            sx={sx}
            textVariant={textVariant}
        />
    );
};
