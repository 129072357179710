import React from "react";
import { Box, Typography } from "@mui/material";
import PDFIcon from "../assets/pdf-icon.svg";
import XlsxIcon from "../assets/xlsx-icon.png";
import DocIcon from "../assets/docx-icon.png";
import PptxIcon from "../assets/pptx-icon.png";
import { FILE_EXTENSIONS_REGEX } from "../search/fileExtensions";
import { FileType } from "../shared/types";

export interface CitedSourcePage {
    page: number;
    rank: number;
}

interface CitedSourceBoxProps {
    fileName: string;
    fileType: FileType | undefined;
    pages: CitedSourcePage[];
    onSelect: ((page: number | undefined) => void) | undefined;
    /**
     * If provided, the page will be highlighted in the UI.
     *
     * This component can work without this, but it's useful for the chat component where no other selection state is shown.
     */
    selectedPage?: number;
}

function getIcon(fileType: FileType | undefined) {
    switch (fileType) {
        case "pdf":
            return PDFIcon;
        case "docx":
            return DocIcon;
        case "xlsx":
            return XlsxIcon;
        case "pptx":
            return PptxIcon;
        default:
            return null;
    }
}

const MAX_PAGES_TO_SHOW = 3;

export const CitedSourceBox: React.FC<CitedSourceBoxProps> = ({
    fileName,
    fileType,
    pages,
    onSelect,
    selectedPage,
}) => {
    const handleSelectWholeCard = React.useMemo(() => (onSelect != null ? () => onSelect(1) : undefined), [onSelect]);

    const isSelected = selectedPage != null;

    const isPlusPageSelected = React.useMemo(() => {
        return (
            pages.length > MAX_PAGES_TO_SHOW &&
            selectedPage != null &&
            pages.slice(MAX_PAGES_TO_SHOW).some(p => p.page === selectedPage)
        );
    }, [pages, selectedPage]);

    return (
        <Box
            sx={{
                width: 240,
                height: 78,
                mr: 1,
                flexShrink: 0,
                flexGrow: 0,
                border: 1,
                borderColor: isSelected ? "selection.main" : "neutrals.20",
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                bgcolor: "white",
                p: 1,
                overflow: "hidden",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", columnGap: 2 }}>
                <Typography
                    variant="body2"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        alignSelf: "flex-start",
                        cursor: onSelect != null ? "pointer" : "default",
                        "&:hover": {
                            bgcolor: onSelect != null ? "action.hover" : "transparent",
                        },
                    }}
                    onClick={handleSelectWholeCard}
                >
                    {fileName.replace(FILE_EXTENSIONS_REGEX, "")}
                </Typography>
                <img src={getIcon(fileType) ?? PDFIcon} alt={`${fileType ?? "PDF"} icon`} width={24} height={24} />
            </Box>
            {pages.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                    }}
                >
                    {pages.length <= MAX_PAGES_TO_SHOW ? (
                        pages.map(p => (
                            <CitedSourceBoxPage
                                key={p.rank}
                                page={p.page}
                                rank={p.rank}
                                fileType={fileType}
                                onSelect={onSelect}
                                isSelected={selectedPage === p.page}
                            />
                        ))
                    ) : (
                        <>
                            {pages.slice(0, MAX_PAGES_TO_SHOW - 1).map(p => (
                                <CitedSourceBoxPage
                                    key={p.rank}
                                    page={p.page}
                                    rank={p.rank}
                                    fileType={fileType}
                                    onSelect={onSelect}
                                    isSelected={selectedPage === p.page}
                                />
                            ))}
                            <CitedSourceBoxPagePlus
                                count={pages.length - (MAX_PAGES_TO_SHOW - 1)}
                                isSomeSelected={isPlusPageSelected}
                            />
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

interface CitedSourceBoxPageProps {
    page: number;
    rank: number;
    fileType: FileType | undefined;
    onSelect: ((page: number | undefined) => void) | undefined;
    isSelected: boolean;
}

const CitedSourceBoxPage: React.FC<CitedSourceBoxPageProps> = ({ page, rank, fileType, onSelect, isSelected }) => {
    const handleClick = React.useMemo(() => (onSelect != null ? () => onSelect(page) : undefined), [onSelect, page]);
    return (
        <Typography
            onClick={handleClick}
            noWrap
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: isSelected ? "selection.main" : "neutrals.20",
                borderRadius: 1,
                cursor: onSelect != null ? "pointer" : "default",
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
                "&:hover": {
                    bgcolor: onSelect != null ? "action.hover" : "transparent",
                },
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "citations.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`[${rank + 1}]`}</Box>
            <Box
                component="span"
                sx={{
                    color: "neutrals.60",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >
                {fileType === "xlsx" ? `Sheet ${page}` : `Pg. ${page}`}
            </Box>
        </Typography>
    );
};

interface CitedSourceBoxPagePlusProps {
    count: number;
    isSomeSelected: boolean;
}

const CitedSourceBoxPagePlus: React.FC<CitedSourceBoxPagePlusProps> = ({ count, isSomeSelected }) => {
    return (
        <Typography
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: isSomeSelected ? "selection.main" : "neutrals.20",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "citations.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`+${count}`}</Box>
        </Typography>
    );
};
