import * as React from "react";
import { Theme, SxProps } from "@mui/material";
import { PDFPreview, PDFPreviewSource } from "../search/pdfPreview";
import { PreviewSectionLayout } from "./previewSectionLayout";

interface PreviewSectionProps {
    source: PDFPreviewSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
    header: React.ReactNode;
    headerSx?: SxProps<Theme>;
}

export const PreviewSection = React.memo(
    ({ source, page, onPageChange, onClose, header, headerSx }: PreviewSectionProps) => {
        return (
            <PreviewSectionLayout header={header} headerSx={headerSx} onClose={onClose}>
                <PDFPreview source={source} page={page} onPageChange={onPageChange} />
            </PreviewSectionLayout>
        );
    },
);

PreviewSection.displayName = "PreviewSection";
