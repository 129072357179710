import * as React from "react";
import { Link, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { SecuritySafe, Sms } from "iconsax-react";
import { FormTextField } from "./formTextField";
import { Helmet } from "react-helmet-async";
import { currentUserQueryOptions } from "../chat/queryOptions";
import { coreAuthRouterGetToken, ErrorOut } from "../backend-client/generated";
import { hasToken } from "../backend-client/authentication";
import { useAuthenticationSuccess } from "../hooks/useAuthenticationSuccess";

export const SignIn: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Sign In - AnswerGrid</title>
            </Helmet>
            <UserAdministrationLayout imageUrl="https://AnswerGrid.b-cdn.net/landscape-at.png">
                <SignInContent />
            </UserAdministrationLayout>
        </>
    );
};

const SignInContent: React.FC = () => {
    const [error, setError] = React.useState<string | null>(null); // State to hold login error message
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const user = useQuery(currentUserQueryOptions);
    const [isRedirecting, setIsRedirecting] = React.useState(false);

    const queryClient = useQueryClient();
    const handleAuthSuccess = useAuthenticationSuccess(queryClient);

    const { mutateAsync, isPending, isSuccess } = useMutation({
        mutationFn: ({ submittedEmail, submittedPassword }: { submittedEmail: string; submittedPassword: string }) =>
            coreAuthRouterGetToken({
                throwOnError: true,
                body: { username: submittedEmail, password: submittedPassword },
                credentials: "include",
            }),
        onSuccess: async response => {
            setIsRedirecting(true);
            await handleAuthSuccess(response.data.token);
        },
        onError: error => {
            console.error("Login error:", error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setError(error.message ?? (error as unknown as ErrorOut).error ?? "Login failed.");
        },
    });

    React.useEffect(() => {
        if (!isRedirecting && hasToken() && user.data != null && !isSuccess && !isPending) {
            navigate("/home", { replace: true });
            setIsRedirecting(true);
            enqueueSnackbar("You are already logged in. Redirecting to home.", { variant: "info" });
        }
    }, [navigate, enqueueSnackbar, user.data, isRedirecting, isSuccess, isPending]);

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await mutateAsync({ submittedEmail: email, submittedPassword: password });
        },
        [mutateAsync, email, password],
    );

    const handleLogin = React.useCallback(async () => {
        await mutateAsync({ submittedEmail: email, submittedPassword: password });
    }, [mutateAsync, email, password]);

    const handleChangeEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleChangePassword = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, []);

    const handleKeyPress = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === "Enter" && email && password) {
                handleLogin().catch(console.error);
            }
        },
        [email, password, handleLogin],
    );

    return (
        <UserAdministrationForm
            title="Sign In"
            actionButtonText="Log in"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleLogin}
            loading={isPending}
        >
            <Box
                component="form"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, display: "flex", flexDirection: "column" }}
            >
                <FormTextField
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleChangeEmail}
                    icon={<Sms size={20} />}
                />
                <FormTextField
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChangePassword}
                    onKeyPress={handleKeyPress}
                    icon={<SecuritySafe size={20} />}
                />
                <Link
                    // TODO: Add forgot password functionality
                    href="/forgot-password"
                    variant="body2"
                    color="secondary"
                    sx={{
                        alignSelf: "flex-end",
                        mt: 1,
                        textDecoration: "none",
                        fontWeight: "medium",
                        display: "inline-block",
                    }}
                >
                    Forgot password?
                </Link>
                {error && (
                    <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </UserAdministrationForm>
    );
};
