import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import { FocusDocumentsDialog } from "./FocusDocumentsDialog";
import { SelectedFile } from "./focusDocuments";
import { ContextSwitcherButton } from "./contextSwitcherButton";
import { ContextIcon } from "./contextIcon";
import { CONTEXT_INFO } from "./contextInfo";

const MENU_ITEM_SX = {
    display: "flex",
    alignItems: "center",
    gap: 1,
    typography: "caption",
    pl: 1.5,
} as const;

interface UserFileContextSwitcherProps {
    focusedDocuments: Array<SelectedFile>;
    onFocusedDocumentsChange: ((docs: Array<SelectedFile>) => void) | undefined;
}

export const UserFileContextSwitcher: React.FC<UserFileContextSwitcherProps> = React.memo(
    ({ focusedDocuments, onFocusedDocumentsChange }: UserFileContextSwitcherProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [isDialogOpen, setIsDialogOpen] = React.useState(false);

        const currentContext = focusedDocuments.length > 0 ? "Focused" : "Global";

        const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);

        const canEditFocus = onFocusedDocumentsChange != null;

        const handleSelect = React.useCallback(
            (context: "Global" | "Focused") => {
                if (context === "Focused" && currentContext === "Global" && canEditFocus) {
                    setIsDialogOpen(true);
                } else {
                    onFocusedDocumentsChange?.(context === "Focused" ? focusedDocuments : []);
                }
                handleClose();
            },
            [currentContext, handleClose, onFocusedDocumentsChange, focusedDocuments, canEditFocus],
        );

        const handleSelectGlobal = React.useCallback(() => {
            handleSelect("Global");
        }, [handleSelect]);

        const handleSelectFocused = React.useCallback(() => {
            handleSelect("Focused");
        }, [handleSelect]);

        const handleDialogClose = React.useCallback(() => {
            setIsDialogOpen(false);
        }, []);

        const handleDialogDone = React.useCallback(
            (selectedFiles: SelectedFile[]) => {
                if (selectedFiles.length > 0) {
                    onFocusedDocumentsChange?.(selectedFiles);
                }
                setIsDialogOpen(false);
            },
            [onFocusedDocumentsChange],
        );

        return (
            <>
                <ContextSwitcherButton
                    canEditFocus={canEditFocus}
                    onClick={handleClick}
                    currentContext={currentContext}
                />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ sx: { py: 0.5 } }}
                >
                    <MenuItem
                        onClick={handleSelectGlobal}
                        sx={{
                            ...MENU_ITEM_SX,
                            cursor: canEditFocus ? "pointer" : "default",
                            "&:hover": {
                                bgcolor: canEditFocus ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!canEditFocus}
                        disableTouchRipple={!canEditFocus}
                    >
                        <ContextIcon context="Global" size={16} />
                        {CONTEXT_INFO.Global.label}
                    </MenuItem>
                    <MenuItem
                        onClick={handleSelectFocused}
                        sx={{
                            ...MENU_ITEM_SX,
                            cursor: canEditFocus ? "pointer" : "default",
                            "&:hover": {
                                bgcolor: canEditFocus ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!canEditFocus}
                        disableTouchRipple={!canEditFocus}
                    >
                        <ContextIcon context="Focused" size={16} />
                        {CONTEXT_INFO.Focused.label}
                    </MenuItem>
                </Menu>
                <FocusDocumentsDialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    onDone={handleDialogDone}
                    selectedFiles={focusedDocuments}
                />
            </>
        );
    },
);

UserFileContextSwitcher.displayName = "ContextSwitcher";
