import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { hasToken } from "../backend-client/authentication";

interface LoggedOutRouteProps {
    homePath: string;
}

/**
 * A route that only renders its children if the user is not logged in.
 * For example, the forgot password page should only be accessible when the user is logged out.
 */
export const LoggedOutRoute: React.FC<LoggedOutRouteProps> = ({ homePath }) => {
    if (hasToken()) {
        return <Navigate to={homePath} />;
    } else {
        return <Outlet />;
    }
};
