import * as React from "react";
import { Box, FormControlLabel, FormGroup, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { Filter } from "./filter";
import { Component } from "iconsax-react";
import { AnswerGridCheckbox } from "../components/answergridCheckbox";
import { SourceType } from "../shared/types";

interface SourceTypeFilterProps {
    selectedSources: SourceType[];
    onSourcesChange: (sources: SourceType[]) => void;
    sx?: SxProps<Theme>;
    disabledReason?: string;
    iconSize?: number;
    textVariant?: TypographyProps["variant"];
}

type SelectableSource = {
    label: string;
    value: SourceType;
};

const sources: SelectableSource[] = [{ label: "Fusioo", value: "fusioo" }];

export const SourceTypeFilter: React.FC<SourceTypeFilterProps> = ({
    selectedSources,
    onSourcesChange,
    sx,
    disabledReason,
    iconSize = 20,
    textVariant = "body2",
}) => {
    const handleSourceChange = React.useCallback(
        (source: SourceType) => {
            onSourcesChange(
                selectedSources.includes(source)
                    ? selectedSources.filter(s => s !== source)
                    : [...selectedSources, source],
            );
        },
        [selectedSources, onSourcesChange],
    );

    const content = React.useMemo(
        () => (
            <Box sx={{ minWidth: 140 }}>
                <FormGroup>
                    {sources.map(source => (
                        <FormControlLabel
                            key={source.value}
                            disabled={disabledReason != null}
                            control={
                                <AnswerGridCheckbox
                                    checked={selectedSources.includes(source.value)}
                                    onChange={() => handleSourceChange(source.value)}
                                />
                            }
                            label={<Typography variant="body2">{source.label}</Typography>}
                            sx={{ py: 0.5, pl: 1 }}
                        />
                    ))}
                </FormGroup>
            </Box>
        ),
        [selectedSources, handleSourceChange, disabledReason],
    );

    return (
        <Filter
            icon={<Component size={iconSize} />}
            text={`Source${selectedSources.length > 0 ? ` (${selectedSources.length})` : ""}`}
            content={content}
            sx={sx}
            textVariant={textVariant}
        />
    );
};
