import * as React from "react";
import { Box, IconButton, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logomark-primary-custom-color.svg";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CitedSourceBox, CitedSourcePage } from "../shared/citedSourceBox";
import { BACKEND_URL } from "../backend-client/url";
import {
    coreChatRouterGetChat,
    CoreChatRouterRespondToUserResponse,
    coreFileStorageRouterGetFiles,
} from "../backend-client/generated";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, chatsQueryOptions, tenantQueryOptions } from "./queryOptions";
import {
    AssistantChatMessage,
    UserChatMessage,
    AssistantChatMessageSource,
    AssistantChatMessageSourcePage,
    ChatOutSchema,
    CoreFileStorageRouterGetFilesResponse,
    ChatResponseSchema,
} from "../backend-client/generated/types.gen";
import {
    ArrowDown2,
    ArrowRight2,
    Like1 as ThumbsUp,
    Dislike as ThumbsDown,
    MessageText,
    Copy,
    TickCircle,
    Send,
} from "iconsax-react";
import { useBoolean } from "../utils/hooks";
import { CitedText } from "../shared/citedText";
import { ChatSplitLayout } from "./chatSplitLayout";
import { ChatHeaderForTenant } from "./chatHeaderForTenant";
import { SelectedFile } from "./context/focusDocuments";
import { ChatInput } from "./chatInput";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { PreviewSection } from "../shared/previewSection";
import { Popover, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { coreChatRouterSubmitChatFeedback } from "../backend-client/generated";
import { Close as CrossIcon } from "@mui/icons-material";
import { H } from "highlight.run";
import { Model } from "./types";
import { DateRangeCallout, useDateRange } from "../search/dateRangeCallout";
import { scrollbarSx } from "../shared/scrollbarProps";
import { FileType, SourceType } from "../shared/types";
import { getSourceFilters } from "./getSourceFilters";

const DEFAULT_SELECTED_PERSONA: SelectedPersona = {
    id: DEFAULT_PERSONA_ID,
    type: "tenant",
};

export const ChatView: React.FC = () => {
    return (
        <SidebarLayout>
            <ChatViewLayoutContent />
        </SidebarLayout>
    );
};

function filterOutNonCitedSources(
    sources: RequiredAssistantChatMessageSource[],
    citedRanks: Set<number>,
): RequiredAssistantChatMessageSource[] {
    return sources
        .map(s => ({
            ...s,
            pages: s.pages?.filter(p => citedRanks.has(p.rank)) ?? [],
        }))
        .filter(s => s.pages.length > 0);
}

interface SelectedSourceInfo {
    systemMessageIdx: number;
    sourceIdx: number;
    page: number;
}

type RequiredAssistantChatMessageSource = Required<AssistantChatMessageSource>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChatViewLayoutContentProps {}

const ChatViewLayoutContent: React.FC<ChatViewLayoutContentProps> = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const [selectedSourceInfo, setSelectedSourceInfo] = React.useState<SelectedSourceInfo | undefined>(undefined);
    const [query, setQuery] = React.useState<string>("");
    const [lastQuery, setLastQuery] = React.useState<string | undefined>(undefined);
    // const lastQueryRef = React.useRef<string | undefined>(undefined);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [isTimeSensitive, setIsTimeSensitive] = React.useState(false);
    const [streamedResponse, setStreamedResponse] = React.useState<string>("");
    const [streamedSources, setStreamedSources] = React.useState<RequiredAssistantChatMessageSource[] | undefined>(
        undefined,
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [model, setModel] = React.useState<Model | undefined>();

    const [projects, setProjects] = React.useState<string[]>([]);
    const [sourceTypes, setSourceTypes] = React.useState<SourceType[]>([]);
    const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
    const [earliestDate, setEarliestDate] = React.useState<Date | undefined>(undefined);
    const [isFiltersExpanded, setIsFiltersExpanded] = React.useState(false);

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollPositionRef = React.useRef(0);

    const {
        data: chat,
        isLoading,
        isError,
    } = useQuery({
        ...chatQueryOptions(chatId ?? ""),
        refetchInterval: isStreaming ? false : undefined,
        refetchOnWindowFocus: !isStreaming,
    });

    useDerivedState(chat, setModel, setProjects, setSourceTypes, setFileTypes, setEarliestDate);

    const queryClient = useQueryClient();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleFetchResponse = React.useCallback(
        async (submittedQuery: string) => {
            if (chatId == null || submittedQuery.trim().length === 0) {
                return;
            }

            setIsStreaming(true);
            setStreamedResponse("");
            setStreamedSources(undefined);
            setLastQuery(submittedQuery);
            setIsTimeSensitive(false);
            const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
            if (token == null) {
                console.error("No auth token found");
                setIsStreaming(false);
                return;
            }

            const url = `${BACKEND_URL}/api/chat/respond/${chatId}`;
            const abortController = new AbortController();

            const body: ChatResponseSchema = {
                query: submittedQuery,
                model: model,
                source_filters: getSourceFilters(projects, sourceTypes, fileTypes, earliestDate),
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                    signal: abortController.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = "";
                let completeResponse = "";
                let completeSources: RequiredAssistantChatMessageSource[] | undefined = undefined;
                let streamedIsTimeSensitive = false;

                const processChunk = async ({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> => {
                    if (done) {
                        if (textBuffer.length > 0) {
                            completeResponse += textBuffer;
                            setStreamedResponse(prev => prev + textBuffer);
                        }
                        return;
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? "";

                        for (const line of lines) {
                            if (line.trim()) {
                                try {
                                    const parsed = JSON.parse(line) as CoreChatRouterRespondToUserResponse;
                                    switch (parsed.t) {
                                        case "r_chnk": {
                                            textBuffer += parsed.d;
                                            const match = textBuffer.match(/(.*?[.,!?;:\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                completeResponse += completeText;
                                                setStreamedResponse(prev => prev + completeText);
                                            }
                                            break;
                                        }
                                        case "sources":
                                            completeSources = parsed.d.map(source => ({
                                                name: source.file_name,
                                                pages: source.pages,
                                                file_type: source.file_type ?? null,
                                                blob_name: source.blob_name ?? null,
                                                is_user_file: source.is_user_file ?? null,
                                                time_created: source.time_created ?? null,
                                                project_code: source.project_code ?? null,
                                            }));
                                            setStreamedSources(completeSources);
                                            break;
                                        case "status":
                                            if (parsed.d === "completed" || parsed.d === "no-results") {
                                                setIsStreaming(false);
                                            }
                                            break;
                                        case "citations": {
                                            const usedRanks = new Set(parsed.d);
                                            completeSources =
                                                completeSources != null
                                                    ? filterOutNonCitedSources(completeSources, usedRanks)
                                                    : undefined;

                                            setStreamedSources(prev =>
                                                prev != null ? filterOutNonCitedSources(prev, usedRanks) : undefined,
                                            );
                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", parsed.d);
                                            setIsStreaming(false);
                                            break;
                                        case "is_time_sensitive":
                                            streamedIsTimeSensitive = parsed.d;
                                            setIsTimeSensitive(streamedIsTimeSensitive);
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                };

                // After we've finished reading the stream, we can apply the same logic from the former effect
                await reader.read().then(processChunk);

                if (completeResponse.trim().length > 0 && chatId != null) {
                    const numMessagesBeforeCompletion = queryClient.getQueryData(chatQueryOptions(chatId).queryKey)
                        ?.messages.length;

                    queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                        const lastUserMessage: UserChatMessage = {
                            role: "user",
                            content: submittedQuery,
                        };
                        const newAssistantMessage: AssistantChatMessage = {
                            role: "assistant",
                            content: completeResponse,
                            sources: completeSources ?? [],
                            are_sources_recency_sensitive: streamedIsTimeSensitive,
                        };
                        if (oldData == null) return oldData;
                        return {
                            ...oldData,
                            messages: [...oldData.messages, lastUserMessage, newAssistantMessage],
                        };
                    });

                    setStreamedResponse("");
                    setLastQuery(undefined);
                    setStreamedSources(undefined);
                    setIsTimeSensitive(false);
                    // We auto-set the name on the BE for the first message, so we need to refetch if this was the first exchange
                    if (numMessagesBeforeCompletion != null && numMessagesBeforeCompletion === 0) {
                        try {
                            const refetchResponse = await coreChatRouterGetChat({ path: { chat_id: chatId } });
                            const refetchedChat = refetchResponse.data;
                            if (refetchedChat != null) {
                                queryClient.setQueryData(chatQueryOptions(chatId).queryKey, old => {
                                    if (old == null) return old;
                                    return { ...old, name: refetchedChat.name };
                                });

                                queryClient.setQueryData(chatsQueryOptions.queryKey, old => {
                                    if (old == null) return old;
                                    return old.map(c =>
                                        c.unique_id === chatId ? { ...c, name: refetchedChat.name } : c,
                                    );
                                });
                            }
                        } catch (error) {
                            console.error("Failed to fetch chat:", error);
                        }
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    if (error.name === "AbortError") {
                        return;
                    }
                    console.error("Stream failed:", error);
                    setIsStreaming(false);
                }
            }

            return () => {
                abortController.abort();
            };
        },
        [chatId, earliestDate, fileTypes, model, projects, queryClient, sourceTypes],
    );

    const handleSubmit = React.useCallback(() => {
        void handleFetchResponse(query);
        setQuery("");
    }, [handleFetchResponse, query]);

    const handleSelectSource = React.useCallback((systemMessageIdx: number, sourceIdx: number, page: number) => {
        setSelectedSourceInfo({ systemMessageIdx, sourceIdx, page });
    }, []);

    const handleClosePreview = React.useCallback(() => {
        setSelectedSourceInfo(undefined);
    }, []);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSourceInfo(prev => {
            if (prev == null) return undefined;
            return { ...prev, page: newPage };
        });
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) return "Please enter a message";
        if (isStreaming) return "Sending message…";
        if (isLoading) return "Loading chat…";
        return null;
    }, [query, isStreaming, isLoading]);

    // Handle "new" chat in search params
    React.useEffect(() => {
        if (searchParams.has("new")) {
            const storedQuery = sessionStorage.getItem(SESSION_STORAGE_NEW_CHAT_QUERY);

            if (storedQuery != null && storedQuery.trim().length > 0) {
                void handleFetchResponse(storedQuery);
                sessionStorage.removeItem(SESSION_STORAGE_NEW_CHAT_QUERY);
                setSearchParams({}, { replace: true });
            }
        }
    }, [searchParams, handleFetchResponse, setSearchParams]);

    const selectedSource = React.useMemo((): AssistantChatMessageSource | undefined => {
        if (selectedSourceInfo?.sourceIdx == null && selectedSourceInfo?.systemMessageIdx == null) return undefined;
        const message = chat?.messages[selectedSourceInfo.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.sources[selectedSourceInfo.sourceIdx];
    }, [chat?.messages, selectedSourceInfo?.systemMessageIdx, selectedSourceInfo?.sourceIdx]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRef.current && !isStreaming) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
    }, [isStreaming]);

    const isInitialLoad = React.useRef(true);

    React.useEffect(() => {
        if (
            scrollRef.current &&
            chat?.messages.length != null &&
            chat.messages.length > 0 &&
            isInitialLoad.current &&
            !scrollPositionRef.current
        ) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
            isInitialLoad.current = false;
        }
    }, [chat?.messages.length, scrollRef]);

    const scrollToBottom = React.useCallback((element: HTMLDivElement) => {
        requestAnimationFrame(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: "smooth",
            });
        });
    }, []);

    const debouncedScrollToBottom = React.useMemo(() => {
        let timeoutId: number;
        return (element: HTMLDivElement) => {
            if (timeoutId) {
                cancelAnimationFrame(timeoutId);
            }
            timeoutId = requestAnimationFrame(() => {
                scrollToBottom(element);
            });
        };
    }, [scrollToBottom]);

    React.useEffect(() => {
        if (isStreaming && scrollRef.current) {
            debouncedScrollToBottom(scrollRef.current);
        }
    }, [isStreaming, debouncedScrollToBottom, streamedResponse]);

    const patchedChat = React.useMemo(() => {
        if (isStreaming && lastQuery != null && chat != null) {
            const userMessage: UserChatMessage = { role: "user", content: lastQuery };
            return { ...chat, messages: [...chat.messages, userMessage] };
        }
        return chat;
    }, [chat, isStreaming, lastQuery]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    const initialData = React.useMemo((): CoreFileStorageRouterGetFilesResponse | null => {
        if (chat?.user_file_scope == null) return null;
        return chat.user_file_scope.map(fileId => ({
            unique_id: fileId,
            blob_name: fileId,
            file_name: fileId,
            file_type: "pdf",
            is_indexing: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        }));
    }, [chat?.user_file_scope]);

    const { data: files } = useQuery({
        queryKey: ["files", chat?.user_file_scope],
        queryFn: async () => {
            if (chat?.user_file_scope == null) return null;
            return coreFileStorageRouterGetFiles({
                body: { file_ids: chat.user_file_scope },
            }).then(res => res.data);
        },
        enabled: chat?.user_file_scope != null && chat.user_file_scope.length > 0,
        select: (data): SelectedFile[] =>
            data?.map<SelectedFile>(file => ({
                id: file.unique_id,
                name: file.file_name,
                fileType: file.file_type,
                isIndexing: file.is_indexing,
                createdAt: file.created_at,
                updatedAt: file.updated_at,
            })) ?? [],
        initialData,
    });

    const selectedPersona = React.useMemo((): SelectedPersona => {
        if (patchedChat?.user_persona != null) {
            return { id: patchedChat.user_persona.unique_id, type: "user" };
        }
        if (patchedChat?.tenant_persona != null) {
            return { id: patchedChat.tenant_persona.unique_id, type: "tenant" };
        }
        return DEFAULT_SELECTED_PERSONA;
    }, [patchedChat]);

    if (chatId == null) {
        return (
            <SidebarLayout>
                <Typography>No chat selected</Typography>
            </SidebarLayout>
        );
    }

    const mainContent = (
        <ChatViewContent
            chat={chat}
            lastQuery={lastQuery}
            isLoading={isLoading}
            isError={isError}
            isStreaming={isStreaming}
            isTimeSensitive={isTimeSensitive}
            streamedResponse={streamedResponse}
            streamedSources={streamedSources}
            query={query}
            disabledReason={disabledReason}
            selectedSourceInfo={selectedSourceInfo}
            model={model}
            onQueryChange={handleQueryChange}
            onSubmit={handleSubmit}
            onSourceSelect={handleSelectSource}
            onModelChange={setModel}
        />
    );

    const header = (
        <ChatHeaderForTenant
            tenant={tenant}
            chatId={chatId}
            chatName={patchedChat?.name}
            isLoading={isLoading}
            tenantFocusedDocumentNames={chat?.file_scope}
            // TODO: what happens if a fiel is deleted that was used in focus? We should still show
            focusedDocuments={files}
            onFocusedDocumentsChange={undefined}
            selectedPersona={selectedPersona}
            onSelectedPersonaChange={undefined}
            disabledEditingFiltersReason={isStreaming ? "Sending message…" : undefined}
            projects={projects}
            onProjectsChange={setProjects}
            sourceTypes={sourceTypes}
            onSourceTypesChange={setSourceTypes}
            fileTypes={fileTypes}
            onFileTypesChange={setFileTypes}
            earliestDate={earliestDate}
            onEarliestDateChange={setEarliestDate}
            isFiltersExpanded={isFiltersExpanded}
            onFiltersExpandedChange={setIsFiltersExpanded}
            hideSourceFilters={chat?.is_direct_base_model_chat ?? false}
        />
    );
    return (
        <ChatSplitLayout
            header={header}
            mainContent={mainContent}
            preview={
                selectedSource && (
                    <ChatPreviewSection
                        source={selectedSource}
                        page={selectedSourceInfo?.page ?? 1}
                        onPageChange={handlePageChange}
                        onClose={handleClosePreview}
                    />
                )
            }
            mainContentProps={{
                bgcolor: "primary.main",
                borderRadius: 3,
                border: 1,
                borderColor: "neutrals.30",
            }}
            scrollRef={scrollRef}
            onScroll={saveScrollPosition}
        />
    );
};

type SelectedSourceInfoForMessage = Omit<SelectedSourceInfo, "systemMessageIdx">;

interface ChatViewContentProps {
    // scrollRef: React.RefObject<HTMLDivElement>;
    // onScroll: () => void;
    chat: ChatOutSchema | undefined;
    lastQuery: string | undefined;
    isLoading: boolean;
    isError: boolean;
    isStreaming: boolean;
    isTimeSensitive: boolean;
    streamedResponse: string;
    streamedSources: AssistantChatMessageSource[] | undefined;
    query: string;
    disabledReason: string | null;
    selectedSourceInfo: SelectedSourceInfo | undefined;
    model: Model | undefined;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onSourceSelect: (systemMessageIdx: number, sourceIdx: number, page: number) => void;
    onModelChange: (model: Model) => void;
}

const ChatViewContent: React.FC<ChatViewContentProps> = ({
    // scrollRef,
    // onScroll,
    chat,
    lastQuery,
    isLoading,
    isError,
    isStreaming,
    isTimeSensitive,
    streamedResponse,
    streamedSources,
    query,
    disabledReason,
    selectedSourceInfo,
    model,
    onQueryChange,
    onSubmit,
    onSourceSelect,
    onModelChange,
}) => {
    const { data: tenant } = useQuery(tenantQueryOptions);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                borderRadius: 3,
                // mb: 2,
                // height: "100%",
                bgcolor: "primary.main",
            }}
        >
            <Box
                // ref={scrollRef}
                // onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    px: 2,
                    pt: 2,
                }}
            >
                {isLoading ? (
                    <Typography>Loading chat…</Typography>
                ) : isError ? (
                    <Typography>Error loading chat</Typography>
                ) : (
                    <>
                        {chat?.messages.map((message, index) =>
                            message.role === "user" ? (
                                <UserMessage key={index} content={message.content} />
                            ) : (
                                <SystemMessage
                                    key={index}
                                    content={message.content}
                                    sources={message.sources}
                                    systemMessageIdx={index}
                                    onSourceSelect={onSourceSelect}
                                    areSourcesRecencySensitive={message.are_sources_recency_sensitive ?? false}
                                    selectedSourceInfo={
                                        selectedSourceInfo?.systemMessageIdx === index ? selectedSourceInfo : undefined
                                    }
                                />
                            ),
                        )}
                        {lastQuery != null && <UserMessage content={lastQuery} />}
                        {isStreaming && (
                            <SystemMessage
                                content={streamedResponse}
                                sources={streamedSources}
                                systemMessageIdx={chat?.messages.length ?? 0}
                                onSourceSelect={undefined}
                                selectedSourceInfo={undefined}
                                areSourcesRecencySensitive={isTimeSensitive}
                                isStreaming={isStreaming}
                            />
                        )}
                    </>
                )}
            </Box>
            <Box
                sx={{
                    position: "sticky",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: "primary.main",
                    pb: 2,
                    maxWidth: "100%",
                }}
            >
                <ChatInput
                    query={query}
                    onQueryChange={onQueryChange}
                    onQuerySubmit={onSubmit}
                    disabledReason={disabledReason}
                    attachDisabledReason={
                        (tenant?.can_chat_with_docs ?? false)
                            ? "You can only attach files to your first message. To attach new files, start a new chat"
                            : undefined
                    }
                    isDirectBaseModelChat={chat?.is_direct_base_model_chat ?? false}
                    onDirectBaseModelChatToggle={undefined}
                    model={model}
                    onModelChange={onModelChange}
                />
            </Box>
        </Box>
    );
};

// New components
const UserMessage: React.FC<{ content: string }> = ({ content }) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Box
            sx={{
                maxWidth: "75%",
                px: 2,
                py: 1.5,
                borderRadius: 2,
                bgcolor: "surface.75",
            }}
        >
            <Typography whiteSpace="pre-wrap">{content}</Typography>
        </Box>
    </Box>
);

interface SystemMessageProps {
    content: string;
    sources: AssistantChatMessageSource[] | undefined;
    systemMessageIdx: number;
    selectedSourceInfo: SelectedSourceInfoForMessage | undefined;
    areSourcesRecencySensitive: boolean;
    isStreaming?: boolean;
    onSourceSelect: ((systemMessageIdx: number, sourceIdx: number, page: number) => void) | undefined;
}

const LOGO_SIZE = 24;

const dateRangeSx = { height: 28, maxHeight: 28, mb: 1 } as const;

const SystemMessage: React.FC<SystemMessageProps> = ({
    content,
    sources,
    systemMessageIdx,
    selectedSourceInfo,
    areSourcesRecencySensitive,
    isStreaming = false,
    onSourceSelect,
}) => {
    const theme = useTheme();
    const { value: showSources, toggleValue: toggleShowSources } = useBoolean(true);
    const {
        value: showMessageActions,
        setTrue: setShowFeedbackIcons,
        setFalse: setHideFeedbackIcons,
    } = useBoolean(false);
    const [textFeedbackAnchorEl, setTextFeedbackAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const dateRange = useDateRange(sources);

    const handleSourceSelect = React.useMemo(
        () =>
            onSourceSelect != null
                ? (sourceIdx: number, page: number | undefined) =>
                      onSourceSelect(systemMessageIdx, sourceIdx, page ?? 1)
                : undefined,
        [onSourceSelect, systemMessageIdx],
    );

    const handleSelectSourceByRank = React.useCallback(
        (rank: number) => {
            if (onSourceSelect == null) {
                return;
            }
            const source = sources
                ?.flatMap((s, sourceIdx) => s.pages?.map(p => ({ sourceIdx, page: p })))
                .find(p => p?.page.rank === rank);
            if (source != null) {
                onSourceSelect(systemMessageIdx, source.sourceIdx, source.page.page);
            }
        },
        [onSourceSelect, sources, systemMessageIdx],
    );

    const hasSources = sources != null && sources.length > 0;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                mb: 2,
                position: "relative",
                lineHeight: "1.75",
            }}
            onMouseEnter={setShowFeedbackIcons}
            onMouseLeave={setHideFeedbackIcons}
        >
            <Box sx={{ maxWidth: "100%", p: 2, display: "flex", overflowX: "hidden", gap: 1 }}>
                <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
                    <Logo
                        style={{
                            width: LOGO_SIZE,
                            height: LOGO_SIZE,
                            marginRight: theme.spacing(1),
                            color: theme.palette.secondary.main,
                        }}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", gap: 1 }}>
                    {/* n relevant docs in muted text */}
                    {hasSources && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" color="neutrals.50">
                                {sources.length} relevant {sources.length === 1 ? "doc" : "docs"}
                            </Typography>
                            <IconButton size="small" onClick={toggleShowSources}>
                                {showSources ? (
                                    <ArrowDown2 size={12} color={theme.palette.neutrals[50]} />
                                ) : (
                                    <ArrowRight2 size={12} color={theme.palette.neutrals[50]} />
                                )}
                            </IconButton>
                        </Box>
                    )}
                    {showSources && hasSources && (
                        <Box
                            sx={{
                                display: "flex",
                                columnGap: 1,
                                alignItems: "center",
                                overflowX: "auto",
                                mt: 1,
                                flexShrink: 0,
                                ...scrollbarSx,
                            }}
                        >
                            {sources?.map((source, idx) => (
                                <ChatCitedSourceBox
                                    key={idx}
                                    fileName={source.name}
                                    fileType={source.file_type}
                                    pages={source.pages ?? []}
                                    sourceIdx={idx}
                                    onSelect={handleSourceSelect}
                                    selectedPage={
                                        selectedSourceInfo?.sourceIdx === idx ? selectedSourceInfo.page : undefined
                                    }
                                />
                            ))}
                        </Box>
                    )}
                    {dateRange != null && areSourcesRecencySensitive && (
                        <DateRangeCallout dateRange={dateRange} sx={dateRangeSx} />
                    )}
                    {isStreaming && content === "" ? (
                        <PulsatingDot hasSources={hasSources} />
                    ) : (
                        <CitedText text={content} onCitationClick={handleSelectSourceByRank} />
                    )}
                </Box>

                <MessageActions
                    content={content}
                    textFeedbackAnchorEl={textFeedbackAnchorEl}
                    setTextFeedbackAnchorEl={setTextFeedbackAnchorEl}
                    isVisible={!isStreaming && (showMessageActions || textFeedbackAnchorEl != null)}
                />
            </Box>
        </Box>
    );
};

const PulsatingDot: React.FC<{ hasSources: boolean }> = ({ hasSources }) => {
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: hasSources ? "flex-start" : "center",
                mb: 1,
            }}
        >
            <Box
                sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    bgcolor: "secondary.main",
                    animation: "pulsate 1.5s ease-in-out infinite",
                    "@keyframes pulsate": {
                        "0%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                        "50%": {
                            transform: "scale(1.05)",
                            opacity: 0.9,
                        },
                        "100%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                    },
                }}
            />
        </Box>
    );
};

interface MessageActionsProps {
    content: string;
    textFeedbackAnchorEl: HTMLButtonElement | null;
    isVisible: boolean;
    setTextFeedbackAnchorEl: (anchorEl: HTMLButtonElement | null) => void;
}

type ActionType = "copy" | "thumbs_up" | "thumbs_down" | "text";
type FeedbackState = { success?: boolean; error?: boolean };
type FeedbackStates = Record<ActionType, FeedbackState>;

const MessageActions: React.FC<MessageActionsProps> = ({
    content,
    textFeedbackAnchorEl,
    setTextFeedbackAnchorEl,
    isVisible,
}) => {
    const [feedbackStates, setFeedbackStates] = React.useState<FeedbackStates>({
        copy: {},
        thumbs_up: {},
        thumbs_down: {},
        text: {},
    });

    const resetFeedbackState = React.useCallback((actionType: ActionType) => {
        setTimeout(() => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: {} }));
        }, 2000);
    }, []);

    const handleActionSuccess = React.useCallback(
        (actionType: ActionType) => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: { success: true } }));
            resetFeedbackState(actionType);
        },
        [resetFeedbackState],
    );

    const handleActionError = React.useCallback(
        (actionType: ActionType) => {
            setFeedbackStates(prev => ({ ...prev, [actionType]: { error: true } }));
            resetFeedbackState(actionType);
        },
        [resetFeedbackState],
    );

    const [textFeedback, setTextFeedback] = React.useState("");
    const { chatId } = useParams<{ chatId: string }>();

    const feedbackMutation = useMutation({
        mutationFn: async ({
            feedbackType,
            textFeedback,
        }: {
            feedbackType: "thumbs_up" | "thumbs_down" | "text";
            textFeedback?: string;
        }) => {
            if (!chatId) return;
            return coreChatRouterSubmitChatFeedback({
                path: { chat_id: chatId },
                body: {
                    feedback_type: feedbackType,
                    text_feedback: textFeedback,
                    message_content: content,
                },
            });
        },
        onMutate: ({ feedbackType }) => {
            H.track("chat_feedback", { feedback_type: feedbackType });
        },
    });

    const handleFeedbackClick = React.useCallback(
        (type: Extract<ActionType, "thumbs_up" | "thumbs_down">) => {
            feedbackMutation.mutate(
                { feedbackType: type },
                {
                    onSuccess: () => handleActionSuccess(type),
                    onError: () => handleActionError(type),
                },
            );
        },
        [feedbackMutation, handleActionSuccess, handleActionError],
    );

    const handleCopyClick = React.useCallback(() => {
        navigator.clipboard
            .writeText(content)
            .then(() => handleActionSuccess("copy"))
            .catch(() => handleActionError("copy"));
    }, [content, handleActionSuccess, handleActionError]);

    const handleTextFeedbackClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setTextFeedbackAnchorEl(event.currentTarget);
        },
        [setTextFeedbackAnchorEl],
    );

    const handleTextFeedbackClose = React.useCallback(() => {
        setTextFeedbackAnchorEl(null);
        setTextFeedback("");
    }, [setTextFeedbackAnchorEl]);

    const handleTextFeedbackSubmit = React.useCallback(() => {
        if (textFeedback.trim()) {
            feedbackMutation.mutate(
                {
                    feedbackType: "text",
                    textFeedback: textFeedback.trim(),
                },
                {
                    onSuccess: () => handleActionSuccess("text"),
                    onError: () => handleActionError("text"),
                },
            );
            handleTextFeedbackClose();
        }
    }, [textFeedback, feedbackMutation, handleTextFeedbackClose, handleActionSuccess, handleActionError]);

    const handleChangeTextFeedback = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFeedback(event.target.value);
    }, []);

    const theme = useTheme();

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleTextFeedbackSubmit();
            }
        },
        [handleTextFeedbackSubmit],
    );

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    bottom: -8,
                    left: `calc(${LOGO_SIZE}px + ${theme.spacing(3)} + 4px)`,
                    display: "flex",
                    gap: 0.5,
                    opacity: isVisible ? 1 : 0,
                    transition: theme =>
                        theme.transitions.create(["opacity"], { duration: 200, easing: "ease-in-out" }),
                }}
            >
                <IconButton size="small" onClick={handleCopyClick} disableFocusRipple disableTouchRipple disableRipple>
                    <ActionIcon actionType="copy" state={feedbackStates.copy} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => handleFeedbackClick("thumbs_up")}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="thumbs_up" state={feedbackStates.thumbs_up} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => handleFeedbackClick("thumbs_down")}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="thumbs_down" state={feedbackStates.thumbs_down} />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleTextFeedbackClick}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                >
                    <ActionIcon actionType="text" state={feedbackStates.text} />
                </IconButton>
            </Box>

            <Popover
                open={Boolean(textFeedbackAnchorEl)}
                anchorEl={textFeedbackAnchorEl}
                onClose={handleTextFeedbackClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ position: "relative" }}>
                        <TextField
                            autoFocus
                            placeholder="Share your thoughts on this response…"
                            value={textFeedback}
                            onChange={handleChangeTextFeedback}
                            onKeyDown={handleKeyDown}
                            multiline
                            minRows={2}
                            maxRows={4}
                            size="small"
                            sx={{
                                minWidth: 350,
                                "& .MuiInputBase-input": {
                                    typography: "body2",
                                    pr: 5, // Make room for the send button
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    typography: "body2",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "neutrals.20",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "neutrals.20",
                                    },
                                },
                            }}
                        />
                        <IconButton
                            onClick={handleTextFeedbackSubmit}
                            disabled={!textFeedback.trim()}
                            size="small"
                            sx={{
                                position: "absolute",
                                right: 8,
                                bottom: 8,
                                bgcolor: textFeedback.trim() ? "secondary.main" : "divider",
                                width: 24,
                                height: 24,
                                "&:hover": {
                                    bgcolor: textFeedback.trim() ? "secondary.dark" : "divider",
                                },
                            }}
                        >
                            <Send size={16} variant="Bold" color={theme.palette.common.white} />
                        </IconButton>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

interface ActionIconProps {
    actionType: ActionType;
    state: FeedbackState;
    size?: number;
}

const ActionIcon: React.FC<ActionIconProps> = ({ actionType, state, size = 14 }) => {
    const theme = useTheme();

    if (state?.success) {
        return <TickCircle size={size} color={theme.palette.success.main} variant="Bold" />;
    }

    if (state?.error) {
        return <CrossIcon sx={{ fontSize: size, color: theme.palette.error.main }} />;
    }

    switch (actionType) {
        case "copy":
            return <Copy size={size} color={theme.palette.neutrals[50]} />;
        case "thumbs_up":
            return <ThumbsUp size={size} color={theme.palette.neutrals[50]} />;
        case "thumbs_down":
            return <ThumbsDown size={size} color={theme.palette.neutrals[50]} />;
        case "text":
            return <MessageText size={size} color={theme.palette.neutrals[50]} />;
    }
};

interface ChatCitedSourceBoxProps {
    fileName: string;
    fileType: AssistantChatMessageSource["file_type"];
    pages: AssistantChatMessageSourcePage[];
    sourceIdx: number;
    onSelect: ((sourceIdx: number, page: number | undefined) => void) | undefined;
    selectedPage: number | undefined;
}

const EMPTY_PAGES: CitedSourcePage[] = [];

const ChatCitedSourceBox: React.FC<ChatCitedSourceBoxProps> = ({
    fileName,
    fileType,
    pages,
    sourceIdx,
    onSelect,
    selectedPage,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => (onSelect != null ? onSelect(sourceIdx, page) : undefined),
        [onSelect, sourceIdx],
    );
    const citedSourcePages = React.useMemo((): CitedSourcePage[] => {
        return pages != null ? pages.map<CitedSourcePage>(p => ({ page: p.page, rank: p.rank })) : EMPTY_PAGES;
    }, [pages]);

    return (
        <CitedSourceBox
            fileName={fileName}
            fileType={fileType ?? undefined}
            pages={citedSourcePages}
            onSelect={handleSelect}
            selectedPage={selectedPage}
        />
    );
};

const headerSx: SxProps<Theme> = { mb: 2 };

const ChatPreviewSection: React.FC<{
    source: AssistantChatMessageSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const previewSource = React.useMemo(() => {
        return {
            file_name: source.name,
            pages: source.pages ?? [],
            blob_name: source.blob_name ?? undefined,
            is_user_file: source.is_user_file ?? false,
            time_created: source.time_created ?? undefined,
            project_code: source.project_code ?? undefined,
        };
    }, [source]);

    const header = React.useMemo(() => {
        return (
            <Typography variant="h6" color="secondary.main" noWrap>
                {source.name}
            </Typography>
        );
    }, [source.name]);

    return (
        <PreviewSection
            source={previewSource}
            page={page}
            onPageChange={onPageChange}
            onClose={onClose}
            header={header}
            headerSx={headerSx}
        />
    );
};

function useDerivedState(
    chat: ChatOutSchema | undefined,
    setModel: (model: Model) => void,
    setProjects: (projects: string[]) => void,
    setSourceTypes: (sourceTypes: SourceType[]) => void,
    setFileTypes: (fileTypes: FileType[]) => void,
    setEarliestDate: (earliestDate: Date | undefined) => void,
) {
    React.useEffect(() => {
        if (chat?.model != null) {
            setModel(chat.model);
        }
    }, [chat?.model, setModel]);

    React.useEffect(() => {
        if (chat?.source_filters?.projects != null) {
            setProjects(chat.source_filters.projects);
        }
    }, [chat?.source_filters?.projects, setProjects]);

    React.useEffect(() => {
        if (chat?.source_filters?.source_types != null) {
            setSourceTypes(chat.source_filters.source_types);
        }
    }, [chat?.source_filters?.source_types, setSourceTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.file_types != null) {
            setFileTypes(chat.source_filters.file_types);
        }
    }, [chat?.source_filters?.file_types, setFileTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.earliest_date != null) {
            setEarliestDate(new Date(chat.source_filters.earliest_date));
        }
    }, [chat?.source_filters?.earliest_date, setEarliestDate]);
}
