import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip, alpha, Menu, MenuItem, Typography } from "@mui/material";
import { ArrowUp, Paperclip, Setting4, GpsSlash, Gps } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { useQuery } from "@tanstack/react-query";
import { tenantQueryOptions } from "./queryOptions";
import { Model } from "./types";
import { comparatorOnFields } from "../utils/comparators";

interface ChatInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    onAttach?: () => void;
    attachDisabledReason?: string;
    isDirectBaseModelChat: boolean;
    onDirectBaseModelChatToggle: (() => void) | undefined;
    sx?: SxProps<Theme>;
    model: Model | undefined;
    onModelChange: (model: Model) => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({
    query,
    onQueryChange,
    onQuerySubmit,
    onAttach,
    attachDisabledReason,
    disabledReason,
    isDirectBaseModelChat,
    onDirectBaseModelChatToggle,
    sx,
    model,
    onModelChange,
}) => {
    const theme = useTheme();

    const tenant = useQuery(tenantQueryOptions);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "white",
                    borderRadius: 2,
                    border: 1,
                    borderColor: "neutrals.25",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Input
                autoFocus
                placeholder="Message AnswerGrid"
                value={query}
                onChange={onQueryChange}
                onKeyDown={handleKeyDown}
                fullWidth
                disableUnderline
                multiline
                maxRows={8}
                sx={{
                    px: 1.5,
                    pt: 1,
                    pb: 0.5,
                    "& .MuiInputBase-input": {
                        ...getScrollbarSx("white"),
                    },
                }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <AttachButton onAttach={onAttach} disabledReason={attachDisabledReason} />
                    {(tenant.data?.can_chat_with_base_model ?? false) && (
                        <ContextModeButton
                            isDirectBaseModelChat={isDirectBaseModelChat}
                            onToggle={onDirectBaseModelChatToggle}
                            model={model ?? "chatgpt-4o-latest"}
                            onModelChange={onModelChange}
                        />
                    )}
                </Box>
                <Box sx={{ flex: 1 }} />
                <Tooltip title={disabledReason || ""}>
                    <span>
                        <IconButton sx={{ p: 1 }} disabled={disabledReason != null} onClick={onQuerySubmit}>
                            <Box
                                sx={{
                                    bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 24,
                                    height: 24,
                                }}
                            >
                                <ArrowUp size={24} color={theme.palette.common.white} />
                            </Box>
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};

interface AttachButtonProps {
    onAttach: (() => void) | undefined;
    disabledReason?: string;
}

const AttachButton: React.FC<AttachButtonProps> = ({ onAttach, disabledReason }) => {
    const theme = useTheme();
    return (
        <Tooltip title={disabledReason ?? ""}>
            <span>
                <IconButton
                    size="small"
                    disableRipple
                    disabled={onAttach == null}
                    sx={{ ml: 1, "&:hover": { backgroundColor: "transparent" } }}
                    onClick={onAttach}
                >
                    <Paperclip
                        size={24}
                        color={onAttach == null ? theme.palette.neutrals[60] : theme.palette.neutrals[80]}
                    />
                </IconButton>
            </span>
        </Tooltip>
    );
};

const modelNames: Record<Model, { name: string; description: string; hideInMenu?: boolean; order?: number }> = {
    "chatgpt-4o-latest": { name: "GPT-4o", description: "Great for most tasks", order: 0 },
    "gpt-4o": { name: "GPT-4o", description: "Great for most tasks", hideInMenu: true, order: 1 },
    o1: { name: "o1", description: "Uses advanced reasoning", order: 2 },
    "o1-mini": { name: "o1-mini", description: "Faster at reasoning", order: 3 },
    "gpt-4o-mini": { name: "GPT-4o mini", description: "Faster for everyday tasks", order: 4 },
};

interface ContextModeButtonProps {
    isDirectBaseModelChat: boolean;
    onToggle: (() => void) | undefined;
    model: Model;
    onModelChange: (model: Model) => void;
}

const modelMenuItemSx = {
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    px: 1.5,
};

const ContextModeButton: React.FC<ContextModeButtonProps> = React.memo(
    ({ isDirectBaseModelChat, onToggle, model, onModelChange }: ContextModeButtonProps) => {
        const theme = useTheme();
        const [modelMenuAnchor, setModelMenuAnchor] = React.useState<null | HTMLElement>(null);

        const handleModelMenuOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setModelMenuAnchor(event.currentTarget);
        }, []);

        const handleModelMenuClose = React.useCallback(() => {
            setModelMenuAnchor(null);
        }, []);

        const handleModelSelect = React.useCallback(
            (newModel: Model) => {
                onModelChange(newModel);
                handleModelMenuClose();
            },
            [onModelChange, handleModelMenuClose],
        );

        const modelDisplayName = modelNames[model].name;

        return (
            <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                    placement="left"
                    enterDelay={300}
                    title={
                        onToggle == null
                            ? "Context mode can only be changed for the first message"
                            : isDirectBaseModelChat
                              ? "Click to enable context from your firm's documents when chatting"
                              : "Click to disable firm document context and chat directly with AI"
                    }
                >
                    <IconButton
                        size="small"
                        disableRipple
                        onClick={onToggle}
                        sx={{
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                            borderRadius: 2,
                            bgcolor: isDirectBaseModelChat ? alpha(theme.palette.secondary.main, 0.1) : "transparent",
                        }}
                    >
                        {isDirectBaseModelChat ? (
                            <GpsSlash size={24} color={theme.palette.secondary.main} />
                        ) : (
                            <Gps size={24} color={theme.palette.neutrals[80]} />
                        )}
                        {isDirectBaseModelChat && (
                            <Box component="span" sx={{ ml: 0.5, color: "secondary.main", typography: "body2" }}>
                                No context
                            </Box>
                        )}
                    </IconButton>
                </Tooltip>

                {isDirectBaseModelChat && (
                    <>
                        <IconButton
                            size="small"
                            disableRipple
                            onClick={handleModelMenuOpen}
                            sx={{
                                ml: 1,
                                display: "flex",
                                gap: 0.5,
                                alignItems: "center",
                                borderRadius: 2,
                                typography: "body2",
                                color: "neutrals.80",
                            }}
                        >
                            <Setting4 size={20} />
                            <Box component="span">{modelDisplayName}</Box>
                        </IconButton>

                        <Menu
                            anchorEl={modelMenuAnchor}
                            open={Boolean(modelMenuAnchor)}
                            onClose={handleModelMenuClose}
                            sx={{ p: 1 }}
                            MenuListProps={{ sx: { py: 0.5 } }}
                        >
                            {Object.entries(modelNames)
                                .sort(
                                    comparatorOnFields(modelEntry => [modelEntry[1].order ?? Number.POSITIVE_INFINITY]),
                                )
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                .filter(([_, modelEntry]) => !modelEntry.hideInMenu)
                                .map(([m]) => (
                                    <ModelMenuItem
                                        key={m}
                                        model={m as Model}
                                        isSelected={m === model}
                                        onModelChange={handleModelSelect}
                                    />
                                ))}
                        </Menu>
                    </>
                )}
            </Box>
        );
    },
);

ContextModeButton.displayName = "ContextModeButton";

interface ModelMenuItemProps {
    model: Model;
    isSelected: boolean;
    onModelChange: (model: Model) => void;
}

const ModelMenuItem: React.FC<ModelMenuItemProps> = ({ model, isSelected, onModelChange }) => {
    return (
        <MenuItem onClick={() => onModelChange(model)} sx={modelMenuItemSx}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" fontWeight={isSelected ? 550 : "normal"}>
                    {modelNames[model].name}
                </Typography>
                <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>{modelNames[model].description}</Typography>
            </Box>
        </MenuItem>
    );
};
