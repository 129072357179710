import * as React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { hasToken as isLoggedIn } from "../backend-client/authentication";
import { useSnackbar } from "notistack";
import { InfoCircle } from "iconsax-react";
import { SignUpForm } from "./SignUpForm";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { Helmet } from "react-helmet-async";
import { currentUserQueryOptions } from "../chat/queryOptions";
import {
    coreAuthRouterGetInvitationStatus,
    coreAuthRouterSignupViaInvitation,
    ErrorOut,
} from "../backend-client/generated";
import { useAuthenticationSuccess } from "../hooks/useAuthenticationSuccess";

const IMAGE_URL = "https://AnswerGrid.b-cdn.net/the-harvest.png";

export const SignUpViaInvitation: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Invitation - AnswerGrid</title>
            </Helmet>
            <SignUpViaInvitationContent />
        </>
    );
};

export const SignUpViaInvitationContent: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const invitationCode = searchParams.get("code");
    const { enqueueSnackbar } = useSnackbar();

    const user = useQuery(currentUserQueryOptions);

    React.useEffect(() => {
        if (isLoggedIn() && user.data != null) {
            const timeoutId = setTimeout(() => {
                navigate("/home");
                enqueueSnackbar("You are already logged in. Redirecting to home.", { variant: "info" });
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
    }, [enqueueSnackbar, navigate, user.data]);

    const {
        data: invitationStatus,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ["invitationStatus", invitationCode],
        queryFn: () =>
            invitationCode != null
                ? coreAuthRouterGetInvitationStatus({ path: { code: invitationCode } })
                : Promise.resolve(null),
        enabled: invitationCode != null,
    });

    const theme = useTheme();

    if (invitationCode == null) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <InfoCircle color={theme.palette.error.main} size={32} />
                    <Typography variant="h6" align="center" color="error">
                        No invite code provided.
                    </Typography>
                </Box>
            </UserAdministrationLayout>
        );
    }

    if (isLoading) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <CircularProgress
                    sx={{
                        color: "primary.contrastText",
                        alignSelf: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                />
            </UserAdministrationLayout>
        );
    }

    if (isError || invitationStatus == null) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <InfoCircle color={theme.palette.error.main} size={24} />
                    <Typography variant="body1" align="center" color="error">
                        Unable to retrieve invitation details.
                    </Typography>
                </Box>
            </UserAdministrationLayout>
        );
    }

    return (
        <UserAdministrationLayout imageUrl={IMAGE_URL}>
            <SignUpContent
                invitationCode={invitationCode}
                firstName={invitationStatus.data?.first_name ?? ""}
                lastName={invitationStatus.data?.last_name ?? ""}
                email={invitationStatus.data?.email ?? ""}
            />
        </UserAdministrationLayout>
    );
};

const SignUpContent: React.FC<{
    invitationCode: string | null;
    firstName: string;
    lastName: string;
    email: string;
}> = ({ invitationCode, firstName, lastName, email }) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [agreeToPolicy, setAgreeToPolicy] = React.useState(false);

    const handleAgreeToPolicy = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeToPolicy(event.target.checked);
    }, []);

    const queryClient = useQueryClient();
    const handleAuthSuccess = useAuthenticationSuccess(queryClient);

    const signupMutation = useMutation({
        mutationFn: (data: { email: string; password: string; code: string }) =>
            coreAuthRouterSignupViaInvitation({
                body: {
                    username: data.email,
                    password: data.password,
                    code: data.code,
                },
            }),
        onSuccess: async data => {
            if (data.data?.token != null) {
                await handleAuthSuccess(data.data.token);
            } else {
                navigate("/login");
            }
        },
        onError: error => {
            console.error("Sign up failed:", error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setError(error.message ?? (error as unknown as ErrorOut).error ?? "Sign up failed.");
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (password !== confirmPassword) {
                setError("Passwords do not match.");
                return;
            }

            if (!invitationCode) {
                setError("Invalid invite code.");
                return;
            }
            if (!agreeToPolicy) {
                setError("You must agree to the Privacy Policy to sign up.");
                return;
            }

            await signupMutation.mutateAsync({
                email,
                password,
                code: invitationCode,
            });
        },
        [signupMutation, invitationCode, email, password, confirmPassword, agreeToPolicy],
    );

    const handleClick = React.useCallback(async () => {
        if (invitationCode == null) {
            return;
        }
        await signupMutation.mutateAsync({
            email,
            password,
            code: invitationCode,
        });
    }, [signupMutation, email, password, invitationCode]);

    return (
        <UserAdministrationForm
            title="Sign Up"
            actionButtonText="Sign up"
            loading={signupMutation.isPending}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleClick}
        >
            <SignUpForm
                email={email}
                password={password}
                confirmPassword={confirmPassword}
                agreeToPolicy={agreeToPolicy}
                firstName={firstName}
                lastName={lastName}
                onPasswordChange={e => setPassword(e.target.value)}
                onConfirmPasswordChange={e => setConfirmPassword(e.target.value)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                onAgreeChange={handleAgreeToPolicy}
                error={error ?? undefined}
            />
        </UserAdministrationForm>
    );
};
