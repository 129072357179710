import * as React from "react";
import { TenantFocusedDocumentItem } from "./tenantFocusedDocumentItem";
import { FocusedDocumentPopper } from "./focusedDocumentPopper";

interface TenantFocusedDocumentPopperProps {
    tenantFocusedDocumentNames: string[];
    anchorEl: HTMLElement | null;
}

export const TenantFocusedDocumentPopper = ({
    tenantFocusedDocumentNames,
    anchorEl,
}: TenantFocusedDocumentPopperProps) => {
    return (
        <FocusedDocumentPopper anchorEl={anchorEl}>
            {tenantFocusedDocumentNames.map(name => (
                <TenantFocusedDocumentItem key={name} fileName={name} />
            ))}
        </FocusedDocumentPopper>
    );
};
