import * as React from "react";
import { GlobalSearch, Notepad, IconProps } from "iconsax-react";

interface ContextIconProps extends IconProps {
    context: "Global" | "Focused";
}

export const ContextIcon: React.FC<ContextIconProps> = ({ context, ...props }) => {
    switch (context) {
        case "Global":
            return <GlobalSearch {...props} />;
        case "Focused":
            return <Notepad {...props} />;
    }
};
