import * as React from "react";
import { Box, IconButton, Theme, SxProps } from "@mui/material";
import { Close } from "@mui/icons-material";

interface PreviewSectionLayoutProps {
    header: React.ReactNode;
    headerSx?: SxProps<Theme>;
    onClose: () => void;
    children: React.ReactNode;
}

export const PreviewSectionLayout = React.memo(({ header, headerSx, onClose, children }: PreviewSectionLayoutProps) => {
    return (
        <Box sx={{ height: "100%", py: 3, px: 5 }}>
            <Box
                sx={[
                    {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        columnGap: 2.5,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
                ]}
            >
                {header}
                <IconButton onClick={onClose} size="small">
                    <Close sx={{ width: 20, height: 20, mt: -1 }} />
                </IconButton>
            </Box>
            {children}
        </Box>
    );
});

PreviewSectionLayout.displayName = "PreviewSectionLayout";
