import Box from "@mui/material/Box";
import * as React from "react";

export interface UserAdministrationLayoutProps {
    children: React.ReactNode;
    imageUrl: string;
}

export const UserAdministrationLayout: React.FC<UserAdministrationLayoutProps> = ({ children, imageUrl }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                minHeight: "100vh",
                backgroundColor: "#F4F2EF",
                flexShrink: 1,
                pt: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    backgroundColor: "white",
                    borderRadius: 4,
                    mx: 2,
                    minHeight: 0,
                    flexGrow: 1,
                    position: "relative",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                        justifyContent: "stretch",
                        flex: 1,
                        paddingRight: 4,
                        mb: "10%",
                    }}
                >
                    {children}
                </Box>
                <Box
                    component="img"
                    src={imageUrl}
                    alt="Side Image"
                    sx={{
                        width: 266,
                        p: 0.8,
                    }}
                />
            </Box>
        </Box>
    );
};
