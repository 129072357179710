import * as React from "react";
import { ArrowDown2 } from "iconsax-react";
import { Box, Typography, IconButton } from "@mui/material";
import { ContextIcon } from "./contextIcon";

import { ChatContext, CONTEXT_INFO } from "./contextInfo";

interface ContextSwitcherButtonProps {
    canEditFocus: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    currentContext: ChatContext;
}

export const ContextSwitcherButton: React.FC<ContextSwitcherButtonProps> = ({
    canEditFocus,
    onClick,
    currentContext,
}) => (
    <Box
        onClick={canEditFocus ? onClick : undefined}
        sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            bgcolor: "white",
            py: 0.5,
            pl: 1,
            pr: 0.5,
            borderRadius: 2,
            cursor: canEditFocus ? "pointer" : "default",
            border: 1,
            borderColor: "neutrals.30",
            width: 170,
            height: 32,
            justifyContent: "space-between",
        }}
    >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ContextIcon context={currentContext} size={16} />
            <Typography variant="caption" fontWeight={500}>
                Context
            </Typography>
            <Typography variant="caption" noWrap>
                {CONTEXT_INFO[currentContext].label}
            </Typography>
        </Box>
        {canEditFocus && (
            <IconButton size="small" disableRipple disableFocusRipple disableTouchRipple>
                <ArrowDown2 size={12} />
            </IconButton>
        )}
    </Box>
);
