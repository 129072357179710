import * as React from "react";
import { Typography, Box, Button, Paper, useTheme } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { currentUserQueryOptions } from "../chat/queryOptions";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { Lock1, Logout } from "iconsax-react";

export const SettingsView: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { data: currentUser } = useQuery(currentUserQueryOptions);

    const handleChangePassword = React.useCallback(() => {
        navigate("/change-password");
    }, [navigate]);

    const handleSignOut = React.useCallback(() => {
        if (window.confirm("Are you sure you want to sign out?")) {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            navigate("/login");
        }
    }, [navigate]);

    return (
        <SidebarLayout>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                    p: 4,
                }}
            >
                <Typography variant="h5" sx={{ mb: 4, color: "neutrals.60" }}>
                    Settings
                </Typography>

                <Paper
                    sx={{
                        p: 3,
                        maxWidth: 600,
                        // bgcolor: "surface.100",
                        borderRadius: 2,
                    }}
                >
                    <Box sx={{ mb: 4 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box>
                                <Typography variant="caption" color="neutrals.50" sx={{ mb: 0.5 }}>
                                    Name
                                </Typography>
                                <Typography variant="body2">
                                    {currentUser?.first_name} {currentUser?.last_name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption" color="neutrals.50" sx={{ mb: 0.5 }}>
                                    Email
                                </Typography>
                                <Typography variant="body2">{currentUser?.email}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
                        <Button
                            variant="outlined"
                            onClick={handleChangePassword}
                            startIcon={<Lock1 size={20} color={theme.palette.secondary.main} />}
                            sx={{
                                justifyContent: "flex-start",
                                color: "secondary.main",
                                borderColor: "neutrals.30",
                            }}
                        >
                            Change Password
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleSignOut}
                            startIcon={<Logout size={20} color={theme.palette.error.main} />}
                            sx={{
                                justifyContent: "flex-start",
                                color: "error.main",
                                borderColor: "neutrals.30",
                            }}
                        >
                            Sign Out
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </SidebarLayout>
    );
};
