import * as React from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    SxProps,
    Typography,
    Autocomplete,
    TextField,
    Chip,
    Theme,
    TypographyProps,
} from "@mui/material";
import { Filter } from "./filter";
import { Folder } from "iconsax-react";
import { AnswerGridCheckbox } from "../components/answergridCheckbox";

interface ProjectFilterProps {
    selectedProjects: string[];
    onProjectsChange: (projects: string[]) => void;
    mode: "options" | "text-input";
    sx?: SxProps<Theme>;
    disabledReason?: string;
    iconSize?: number;
    textVariant?: TypographyProps["variant"];
}

const projects = Array.from({ length: 4000 }, (_, i) => (i + 1).toString());

export const ProjectFilter: React.FC<ProjectFilterProps> = ({
    selectedProjects,
    onProjectsChange,
    mode,
    sx,
    disabledReason,
    iconSize = 20,
    textVariant = "body2",
}) => {
    const handleProjectChange = React.useCallback(
        (project: string) => {
            onProjectsChange(
                selectedProjects.includes(project)
                    ? selectedProjects.filter(p => p !== project)
                    : [...selectedProjects, project],
            );
        },
        [selectedProjects, onProjectsChange],
    );

    const handleTextInputChange = React.useCallback(
        (_event: React.SyntheticEvent, newValue: string[]) => {
            onProjectsChange(newValue);
        },
        [onProjectsChange],
    );

    const content = React.useMemo(
        () => (
            <Box sx={{ minWidth: 140 }}>
                {mode === "options" ? (
                    <FormGroup>
                        {projects.map(project => (
                            <FormControlLabel
                                key={project}
                                disabled={disabledReason != null}
                                control={
                                    <AnswerGridCheckbox
                                        checked={selectedProjects.includes(project)}
                                        onChange={() => handleProjectChange(project)}
                                    />
                                }
                                label={<Typography variant="body2">{project}</Typography>}
                                sx={{ py: 0.5, pl: 1 }}
                            />
                        ))}
                    </FormGroup>
                ) : (
                    <Box sx={{ p: 0.5, maxWidth: 300 }}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disabled={disabledReason != null}
                            options={selectedProjects}
                            value={selectedProjects}
                            onChange={handleTextInputChange}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        {...getTagProps({ index })}
                                        size="small"
                                        variant="outlined"
                                        label={<Typography variant="caption">{option}</Typography>}
                                        key={index}
                                    />
                                ))
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder="Type a project number"
                                    sx={{ minWidth: 200 }}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            typography: "caption",
                                            minHeight: 40,
                                        },
                                    }}
                                />
                            )}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    p: 1,
                                },
                            }}
                        />
                    </Box>
                )}
            </Box>
        ),
        [selectedProjects, handleProjectChange, handleTextInputChange, mode, disabledReason],
    );

    return (
        <Filter
            icon={<Folder size={iconSize} />}
            text={`Project${selectedProjects.length > 0 ? ` (${selectedProjects.length})` : ""}`}
            content={content}
            sx={sx}
            textVariant={textVariant}
        />
    );
};
