import { ChatHeader } from "./chatHeader";
import { TenantSchema } from "../backend-client/generated/types.gen";
import { ChatHeaderWithExtraFeatures } from "./chatHeaderWithExtraFeatures";
import * as React from "react";
import { SelectedPersona } from "./selectedPersona";
import { SelectedFile } from "./context/focusDocuments";
import { FileType, SourceType } from "../shared/types";

interface ChatHeaderForTenantProps {
    tenant: TenantSchema | undefined;
    chatId: string | undefined;
    chatName: string | undefined;
    isLoading: boolean;
    tenantFocusedDocumentNames: string[] | undefined;
    // TODO: Render the tenant focused documents, not just user ones
    focusedDocuments: Array<SelectedFile>;
    projects: string[];
    isFiltersExpanded: boolean;
    onFiltersExpandedChange: (expanded: boolean) => void;
    onProjectsChange: (projects: string[]) => void;
    sourceTypes: SourceType[];
    onSourceTypesChange: (sourceTypes: SourceType[]) => void;
    fileTypes: FileType[];
    onFileTypesChange: (fileTypes: FileType[]) => void;
    earliestDate: Date | undefined;
    onEarliestDateChange: (date: Date | undefined) => void;
    onFocusedDocumentsChange: ((docs: Array<SelectedFile>) => void) | undefined;
    selectedPersona: SelectedPersona;
    onSelectedPersonaChange: React.Dispatch<React.SetStateAction<SelectedPersona>> | undefined;
    disabledEditingFiltersReason?: string;
    hideSourceFilters?: boolean;
}

export const ChatHeaderForTenant: React.FC<ChatHeaderForTenantProps> = ({
    tenant,
    chatId,
    chatName,
    isLoading,
    tenantFocusedDocumentNames,
    focusedDocuments,
    projects,
    isFiltersExpanded,
    onFiltersExpandedChange,
    onProjectsChange,
    sourceTypes,
    onSourceTypesChange,
    fileTypes,
    onFileTypesChange,
    earliestDate,
    onEarliestDateChange,
    onFocusedDocumentsChange,
    selectedPersona: selectedPersonaId,
    onSelectedPersonaChange,
    disabledEditingFiltersReason,
    hideSourceFilters,
}) => {
    const name = isLoading ? undefined : (chatName ?? "Untitled Chat");

    if (tenant?.enable_personas ?? false) {
        return (
            <ChatHeaderWithExtraFeatures
                chatId={chatId}
                chatName={name}
                focusedDocuments={focusedDocuments}
                onFocusedDocumentsChange={onFocusedDocumentsChange}
                selectedPersona={selectedPersonaId}
                onSelectedPersonaChange={onSelectedPersonaChange}
            />
        );
    }

    return (
        <ChatHeader
            chatId={chatId}
            chatName={name}
            focusedDocuments={focusedDocuments}
            onFocusedDocumentsChange={onFocusedDocumentsChange}
            tenantFocusedDocumentNames={tenantFocusedDocumentNames}
            projects={projects}
            onProjectsChange={onProjectsChange}
            sourceTypes={sourceTypes}
            onSourceTypesChange={onSourceTypesChange}
            fileTypes={fileTypes}
            onFileTypesChange={onFileTypesChange}
            earliestDate={earliestDate}
            onEarliestDateChange={onEarliestDateChange}
            isFiltersExpanded={isFiltersExpanded}
            onFiltersExpandedChange={onFiltersExpandedChange}
            disabledEditingFiltersReason={disabledEditingFiltersReason}
            hideSourceFilters={hideSourceFilters}
        />
    );
};
