export function getSearchUrl(
    query: string,
    projects: string[],
    sources: string[],
    fileTypes: string[],
    earliestDate: Date | undefined,
): string {
    let url = `/search?q=${encodeURIComponent(query)}`;
    if (projects.length > 0) {
        const encodedProjects = projects.map(p => encodeURIComponent(p)).join(",");
        url += `&projects=${encodedProjects}`;
    }
    if (sources.length > 0) {
        const encodedSources = sources.map(s => encodeURIComponent(s)).join(",");
        url += `&sources=${encodedSources}`;
    }
    if (fileTypes.length > 0) {
        const encodedFileTypes = fileTypes.map(ft => encodeURIComponent(ft)).join(",");
        url += `&file_types=${encodedFileTypes}`;
    }
    if (earliestDate != null) {
        url += `&earliest_date=${earliestDate.toISOString().split("T")[0]}`;
    }
    return url;
}
