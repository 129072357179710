import { Box, styled, SxProps, Theme } from "@mui/material";
import * as React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import { CitationNode, remarkCitationPlugin } from "./remarkCitationPlugin";
import { Citation } from "./citation";
import { PluggableList } from "react-markdown/lib";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";

interface CitedTextProps {
    text: string;
    onCitationClick?: (rank: number) => void;
    sx?: SxProps<Theme>;
}

interface CustomComponents extends Omit<Components, "citation"> {
    citation: React.FC<{ node: CitationNode }>;
}

const StyledP = styled("p")({});

const plugins: PluggableList = [remarkCitationPlugin, remarkGfm, [remarkMath, { singleDollarTextMath: false }]];

const rehypePlugins: PluggableList = [rehypeKatex];

const replaceMarkdownCodeBlocksWithPlain = (text: string): string => {
    return text.replace(/```markdown\n([\s\S]*?)```/g, (_, content) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return content as string;
    });
};

export const CitedText: React.FC<CitedTextProps> = ({ text, onCitationClick, sx }) => {
    const replacedText = React.useMemo(() => replaceMarkdownCodeBlocksWithPlain(text), [text]);

    const components = React.useMemo(
        (): Partial<CustomComponents> => ({
            citation: ({ node }) => {
                const index = parseInt(node.children[0].value, 10) - 1;
                return <Citation index={index} onClick={onCitationClick} />;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            table: ({ children, ref: _, ...props }) => (
                <Box sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
                    <Box
                        component="table"
                        sx={{
                            borderCollapse: "collapse",
                        }}
                        {...props}
                    >
                        {children}
                    </Box>
                </Box>
            ),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            tr: ({ children, ref: _, ...props }) => (
                <Box
                    component="tr"
                    sx={{
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        "& th, & td": {
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                            borderColor: "divider",
                        },
                        "& th:first-of-type, & td:first-of-type": {
                            borderLeft: "none",
                        },
                        "& th:last-of-type, & td:last-of-type": {
                            borderRight: "none",
                        },
                    }}
                    {...props}
                >
                    {children}
                </Box>
            ),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            th: ({ children, ref: _, ...props }) => (
                <Box
                    component="th"
                    sx={{
                        p: 1.5,
                        textAlign: "left",
                        fontWeight: "bold",
                        backgroundColor: "surface.50",
                    }}
                    {...props}
                >
                    {children}
                </Box>
            ),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            td: ({ children, ref: _, ...props }) => (
                <Box
                    component="td"
                    sx={{
                        p: 1.5,
                        textAlign: "left",
                    }}
                    {...props}
                >
                    {children}
                </Box>
            ),
            p: ({ children, ref, ...props }) => (
                <StyledP
                    ref={ref}
                    sx={{
                        "&:first-of-type": {
                            mt: 0,
                        },
                        "&:last-of-type": {
                            mb: 0,
                        },
                    }}
                    {...props}
                >
                    {children}
                </StyledP>
            ),
        }),
        [onCitationClick],
    );

    return (
        <Box component="span" sx={sx}>
            <ReactMarkdown remarkPlugins={plugins} rehypePlugins={rehypePlugins} components={components}>
                {replacedText}
            </ReactMarkdown>
        </Box>
    );
};
