import * as React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { PersonData } from "./types";
import { ProjectForPerson, ProjectForPersonProps } from "./projectForPerson";
import { PreviewSectionLayout } from "../shared/previewSectionLayout";
import { SearchViewHeader } from "./searchViewHeader";
import { format } from "date-fns";
import { DocumentThumbnail } from "./documentThumbnail";
import { Calendar, Folder } from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import { PDFPreview, PDFPreviewSource } from "./pdfPreview";
import { projectDocumentNamesQueryOptions } from "../chat/queryOptions";

interface PersonPreviewSectionProps {
    person: PersonData;
    projectIdx?: number;
    fileIdx?: number;
    onSelect: (projectIdx: number | undefined, fileIdx: number | undefined) => void;
    onClose: () => void;
}

export const PersonPreviewSection = React.memo(
    ({ person, projectIdx, fileIdx, onSelect, onClose }: PersonPreviewSectionProps) => {
        const project = projectIdx != null ? person.projects[projectIdx] : undefined;

        const handleBackFromProject = React.useCallback(() => {
            onSelect(undefined, undefined);
        }, [onSelect]);

        const handleBackFromFile = React.useCallback(() => {
            onSelect(projectIdx, undefined);
        }, [onSelect, projectIdx]);

        const header = React.useMemo(
            () => (
                <SearchViewHeader
                    text={
                        fileIdx != null && project != null
                            ? `${project?.name} (${project?.id.split("-")[1]}) - File ${fileIdx + 1}`
                            : project != null
                              ? `Relevant Projects - ${formatName(person.first_name, person.last_name)} - ${project.name} (${project.id.split("-")[1]})`
                              : `Relevant Projects - ${formatName(person.first_name, person.last_name)}`
                    }
                    tooltip={`These are the projects that ${formatName(person.first_name, person.last_name)} has worked on that are most relevant to the area you asked about.`}
                    onBack={
                        fileIdx != null && project != null
                            ? handleBackFromFile
                            : project != null
                              ? handleBackFromProject
                              : undefined
                    }
                />
            ),
            [person.first_name, person.last_name, project, fileIdx, handleBackFromProject, handleBackFromFile],
        );

        const handleSelectProject = React.useCallback(
            (projectIdx: number) => {
                onSelect(projectIdx, undefined);
            },
            [onSelect],
        );

        const handleSelectFile = React.useCallback(
            (fileIdx: number) => {
                onSelect(projectIdx, fileIdx);
            },
            [onSelect, projectIdx],
        );

        return (
            <PreviewSectionLayout header={header} onClose={onClose}>
                <PersonPreviewSectionContent
                    person={person}
                    project={project}
                    fileIdx={fileIdx}
                    handleSelectProject={handleSelectProject}
                    handleSelectFile={handleSelectFile}
                />
            </PreviewSectionLayout>
        );
    },
);

PersonPreviewSection.displayName = "PersonPreviewSection";

interface ProjectFilePreviewSectionProps {
    project: PersonData["projects"][number];
    fileIdx: number;
}

const ProjectFilePreviewSection = React.memo(({ project, fileIdx }: ProjectFilePreviewSectionProps) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const { data: documentNames } = useQuery(projectDocumentNamesQueryOptions(project));

    const previewSource = React.useMemo(
        (): PDFPreviewSource => ({
            file_name: documentNames?.[fileIdx] ?? "Untitled",
            file_type: "pdf",
            pages: [], // You'll need to populate this from your data
            blob_name: project.file_ids[fileIdx],
            is_user_file: false,
        }),
        [documentNames, fileIdx, project.file_ids],
    );

    const handlePageChange = React.useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    return <PDFPreview source={previewSource} page={currentPage} onPageChange={handlePageChange} />;
});

ProjectFilePreviewSection.displayName = "ProjectFilePreviewSection";

interface PersonPreviewSectionContentProps {
    person: PersonData;
    project?: PersonData["projects"][number];
    fileIdx?: number;
    handleSelectProject: (projectIdx: number) => void;
    handleSelectFile: (fileIdx: number) => void;
}

const PersonPreviewSectionContent = React.memo(
    ({ person, project, fileIdx, handleSelectProject, handleSelectFile }: PersonPreviewSectionContentProps) => {
        if (project != null && fileIdx != null) {
            return <ProjectFilePreviewSection project={project} fileIdx={fileIdx} />;
        }
        if (project != null) {
            return (
                <ProjectPreviewSection project={project} personId={person.unique_id} onSelectFile={handleSelectFile} />
            );
        }

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 3 }}>
                {person.projects.map((project, idx) => (
                    <ProjectForPersonWithIdx
                        key={project.id ?? idx}
                        project={project}
                        personId={person.unique_id}
                        idx={idx}
                        onSelect={handleSelectProject}
                    />
                ))}
            </Box>
        );
    },
);

PersonPreviewSectionContent.displayName = "PersonPreviewSectionContent";

interface ProjectForPersonWithIdx extends Omit<ProjectForPersonProps, "onSelect"> {
    idx: number;
    onSelect?: (idx: number) => void;
}

const ProjectForPersonWithIdx = React.memo(({ project, personId, idx, onSelect }: ProjectForPersonWithIdx) => {
    const handleSelect = React.useCallback(() => {
        onSelect?.(idx);
    }, [idx, onSelect]);

    return <ProjectForPerson project={project} personId={personId} onSelect={handleSelect} />;
});

ProjectForPersonWithIdx.displayName = "ProjectForPersonWithIdx";

interface ProjectPreviewSectionProps {
    project: PersonData["projects"][number];
    personId: string;
    onSelectFile?: (fileIdx: number) => void;
}

export const ProjectPreviewSection = React.memo(
    ({ project, personId, onSelectFile: onSelectFile }: ProjectPreviewSectionProps) => {
        const theme = useTheme();

        const { data: documentNames } = useQuery(projectDocumentNamesQueryOptions(project));

        const otherProjectMembers = React.useMemo(() => {
            return project.all_members?.filter(member => member.unique_id !== personId);
        }, [project.all_members, personId]);

        const handleFileClick = React.useCallback(
            (fileIdx: number) => {
                onSelectFile?.(fileIdx);
            },
            [onSelectFile],
        );

        const filesWithNames = React.useMemo(() => {
            return project.file_ids
                .map((fileId, index) => ({
                    file_id: fileId,
                    file_name: documentNames?.[index],
                    index,
                }))
                .filter(file => file.file_name != null);
        }, [project.file_ids, documentNames]);

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant="body2" fontWeight={550} color="neutrals.80">
                        Documents
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", height: 60 }}>
                        {filesWithNames.map(({ file_id, file_name, index }) => (
                            <Box
                                key={file_id}
                                onClick={() => handleFileClick(index)}
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    bgcolor: "white",
                                    border: 1,
                                    borderColor: "neutrals.25",
                                    borderRadius: 2,
                                    p: 1,
                                    width: 220,
                                    cursor: "pointer",
                                    transition: "all 0.2s ease-in-out",
                                    height: 60,
                                    "&:hover": {
                                        bgcolor: "neutrals.5",
                                        borderColor: "neutrals.35",
                                    },
                                }}
                            >
                                <DocumentThumbnail
                                    blobName={file_id}
                                    fileType="pdf"
                                    isUserFile={false}
                                    width={60}
                                    height={40}
                                    sx={{ cursor: "pointer" }}
                                />
                                <Typography
                                    variant="caption"
                                    color="neutrals.80"
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {file_name}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                {otherProjectMembers?.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                bgcolor: "white",
                                border: 1,
                                borderColor: "neutrals.25",
                                borderRadius: 2,
                                p: 1,
                                // width: "fit-content",
                            }}
                        >
                            <Box sx={{ display: "flex" }}>
                                {otherProjectMembers.slice(0, 2).map((member, index) => (
                                    <Avatar
                                        key={member.unique_id}
                                        src={member.profile_picture_url ?? undefined}
                                        alt={`${member.first_name} ${member.last_name}`}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            ml: index === 0 ? 0 : -0.8,
                                            border: 1,
                                            borderColor: "white",
                                            zIndex: index + 1,
                                        }}
                                    />
                                ))}
                            </Box>

                            <Typography variant="caption" color="neutrals.50">
                                {otherProjectMembers.length === 1
                                    ? `${formatName(otherProjectMembers[0].first_name, otherProjectMembers[0].last_name)} collaborated on this project`
                                    : otherProjectMembers.length === 2
                                      ? `${formatName(otherProjectMembers[0].first_name, otherProjectMembers[0].last_name)} and ${formatName(otherProjectMembers[1].first_name, otherProjectMembers[1].last_name)} collaborated on this project`
                                      : otherProjectMembers.length === 3
                                        ? `${otherProjectMembers[0].first_name}, ${otherProjectMembers[1].first_name} and ${otherProjectMembers[2].first_name} collaborated on this project`
                                        : `${otherProjectMembers[0].first_name}, ${otherProjectMembers[1].first_name} and ${
                                              otherProjectMembers.length - 2
                                          } ${otherProjectMembers.length - 2 === 1 ? "other" : "others"} collaborated on this project`}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: "flex", gap: 2, ml: 1 }}>
                    {project.id != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Folder size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                #{project.id.split("-")[1]}
                            </Typography>
                        </Box>
                    )}
                    {project.start_date != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Calendar size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                Started {format(new Date(project.start_date), "MMM yyyy")}
                            </Typography>
                        </Box>
                    )}
                    {project.end_date != null && (
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Calendar size={16} color={theme.palette.neutrals[50]} />
                            <Typography variant="caption" color="neutrals.50">
                                Completed {format(new Date(project.end_date), "MMM yyyy")}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    },
);

ProjectPreviewSection.displayName = "ProjectPreviewSection";

function formatName(firstName: string, lastName: string) {
    return `${firstName} ${lastName}`;
}
