import { ChatSourceFilters } from "../backend-client/generated";
import { FileType, SourceType } from "../shared/types";

export function getSourceFilters(
    projects: string[],
    sourceTypes: SourceType[],
    fileTypes: FileType[],
    earliestDate: Date | undefined,
): ChatSourceFilters | undefined {
    if (projects.length === 0 && sourceTypes.length === 0 && fileTypes.length === 0 && earliestDate == null) {
        return undefined;
    }
    return {
        projects,
        source_types: sourceTypes,
        file_types: fileTypes,
        earliest_date: earliestDate?.toISOString().split("T")[0] ?? null,
    };
}
