import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { identifyUserForSession } from "../auth/userIdentification";
import { useSubdomainRedirect, SUBDOMAIN_REDIRECT_QUERY_PARAM } from "../useSubdomainRedirect";
import { Box } from "@mui/material";

interface ProtectedRouteProps {
    authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authenticationPath }) => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

    useSubdomainRedirect();

    React.useEffect(() => {
        if (token != null) {
            void identifyUserForSession();
        }
    }, [token]);

    const hasRedirectParam = React.useMemo(
        () => new URLSearchParams(window.location.search).has(SUBDOMAIN_REDIRECT_QUERY_PARAM),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [window.location.search],
    );

    if (token != null) {
        return <Outlet />;
    } else if (hasRedirectParam) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            />
        );
    } else {
        return <Navigate to={authenticationPath} />;
    }
};
