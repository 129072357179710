import * as React from "react";
import { Box, Theme, SxProps, Typography, useTheme } from "@mui/material";
import { InfoCircle, LampCharge } from "iconsax-react";
import { isNonNullable } from "../utils/isNonNullable";

interface DateRangeCalloutProps {
    dateRange: {
        minYear: number;
        maxYear: number;
        isOld: boolean;
    };
    sx?: SxProps<Theme>;
}

export const DateRangeCallout: React.FC<DateRangeCalloutProps> = ({ dateRange, sx }) => {
    const theme = useTheme();

    return (
        <Box
            sx={[
                {
                    height: 36,
                    flexBasis: 36,
                    display: "flex",
                    alignItems: "center",
                    px: 1,
                    borderRadius: 1,
                    bgcolor: dateRange.isOld ? "orange.main" : "surface.50",
                    columnGap: 1,
                    mt: 1,
                    flexShrink: 0,
                    flexGrow: 0,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {dateRange.isOld ? (
                <InfoCircle size={16} color={theme.palette.neutrals[100]} variant="Bold" />
            ) : (
                <LampCharge size={16} color={theme.palette.neutrals[100]} variant="Bold" />
            )}
            <Typography
                variant="caption"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "text.primary",
                }}
            >
                {dateRange.minYear === dateRange.maxYear
                    ? `This answer uses sources from ${dateRange.minYear}`
                    : `This answer uses sources from ${dateRange.minYear} - ${dateRange.maxYear}`}
            </Typography>
        </Box>
    );
};

const WARNING_YEARS_OLD = 4;

export function useDateRange<T extends { time_created?: string | undefined | null }>(sources: T[] | undefined) {
    const dateRange = React.useMemo(() => {
        if (sources == null || sources.length === 0) return null;

        const dates = sources
            .map(source => (source.time_created != null ? new Date(source.time_created) : null))
            .filter(isNonNullable);

        if (!dates.length) return null;

        const minYear = Math.min(...dates.map(d => d.getFullYear()));
        const maxYear = Math.max(...dates.map(d => d.getFullYear()));

        return {
            isOld: new Date().getFullYear() - maxYear > WARNING_YEARS_OLD,
            minYear,
            maxYear,
        };
    }, [sources]);

    return dateRange;
}
